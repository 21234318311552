import { createContext } from "react";

interface UserContextI {
    userState: any;
    login: any;
    logout: any;
    signup: any;
    signInWithLink: any;
    reSendEmail: any;
    loading: boolean;
    setLoading: any;
    recoveryPassword: any;
    dispatch: any;
}

const UserContext = createContext<UserContextI>({} as any);

export default UserContext;