import {createNativeStackNavigator} from '@react-navigation/native-stack'
import {NavigationContainer} from '@react-navigation/native'

import MainHome from '../modules/home/Main'
import MainApp from '../modules/app/Main'
import MainTemplate from '../modules/templates/Main'
import MainProfile from '../modules/profile/Main'
import MainMetrics from '../modules/metrics/MainMetrics'
import React from 'react'

const Stack = createNativeStackNavigator();

export default function Main(){

    return (
        <NavigationContainer>
            <Stack.Navigator
                screenOptions={{
                    headerShown: false
                }}
            >
                <Stack.Screen name="Home" component={MainHome}/>
                <Stack.Screen name="App" component={MainApp}/>
                <Stack.Screen name="Templates" component={MainTemplate}/>
                <Stack.Screen name="Profile" component={MainProfile}/>
                <Stack.Screen name="Metrics" component={MainMetrics}/>
            </Stack.Navigator>
        </NavigationContainer>
    )

}