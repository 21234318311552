import { View } from 'react-native';
import { Card, Text as TextPaper } from 'react-native-paper';
import { Button } from 'react-native-paper';
import { useFormik } from "formik";
import { useContext, useState } from 'react';
import { useToast } from "react-native-toast-notifications";
import Input from '../../../components/forms/Input';
import UserContext from '../../../../context/user/UserContext';
import { captureException } from "@sentry/react";


interface RecoveryPasswordProps {
    navigation: any;
    setRecoveryPassword: any;
}

export default function RecoveryPassword({ navigation, setRecoveryPassword }: RecoveryPasswordProps) {

    const { recoveryPassword }: any = useContext(UserContext);

    const [loading, setLoading] = useState(false);

    const toast = useToast();


    const formikRecovery = useFormik({
        initialValues: {
            email: ''
        },
        onSubmit: async (values) => {
            setLoading(true);
            try {
                await recoveryPassword(values.email);
                toast.show("Hemos enviado un correo para recuperar tu contraseña",
                    {
                        type: "success",
                        placement: 'top',
                        animationType: 'zoom-in',
                        style: {
                            borderRadius: 15,
                        },
                    });
            } catch (error: any) {
                captureException(error);
                toast.show(error.message,
                    {
                        type: "danger",
                        placement: 'top',
                        animationType: 'zoom-in',
                        style: {
                            borderRadius: 15,
                        },
                    })

            }
            setLoading(false);
        },
        validate: (values) => {
            let errors: any = {};

            if (!values.email) {
                errors.email = 'El email es requerido';
            }

            return errors;
        }
    });

    return (
        <Card className="mt-3 w-4/5 md:w-3/5" style={{ backgroundColor: "#526D82" }}>
            <Card.Content>

                <Input
                    label='Email'
                    placeholder='Ej: example@eleti.dev'
                    error={formikRecovery.errors.email || ''}
                    existError={formikRecovery.errors.email ? true : false}
                    value={formikRecovery.values.email}
                    onChange={(e: any) => {
                        formikRecovery.setFieldValue('email', e.nativeEvent.text);
                    }}
                    onEnter={formikRecovery.handleSubmit}
                />


                <Button
                    mode="contained"
                    onPress={formikRecovery.handleSubmit}
                    className="m-3"
                    loading={loading}
                >
                    Recuperar contraseña
                </Button>

                {/* sino tienes cuenta registrate */}
                <View className="flex flex-row justify-center items-center">
                    <TextPaper className="text-base">¿Ya tienes cuenta?</TextPaper>
                    <Button
                        mode="contained-tonal"
                        onPress={() => {
                            setRecoveryPassword(false);
                        }}
                        className="m-3"
                    >
                        Iniciar sesion
                    </Button>
                </View>


            </Card.Content>
        </Card>
    )

}