import { View } from 'react-native';
import { Card, Text as TextPaper } from 'react-native-paper';
import { Button } from 'react-native-paper';
import Input from "../../../components/forms/Input";
import { useFormik } from 'formik';
import { useContext, useState } from 'react';
import UserContext from '../../../../context/user/UserContext'
import { useToast } from "react-native-toast-notifications";

interface SignUpProps {
    navigation: any;
    setTypeForm: any;
    typeForm: boolean;
}

export default function SignUp({ navigation, setTypeForm, typeForm }: SignUpProps) {

    const { signup } = useContext(UserContext);

    const [loading, setLoading] = useState(false);
    
    const toast = useToast();

    const signupFormik = useFormik({
        initialValues: {
            name: '',
            lastName: '',
            email: '',
            password: ''
        },
        onSubmit: async (values) => {
            setLoading(true);
            try {
                await signup(values.email, values.password);

                window.localStorage.setItem('efs', values.email);
                window.localStorage.setItem('n', values.name);
                window.localStorage.setItem('ln', values.lastName);

                toast.show("Cuenta creada con exito, hemos enviado un correo de verificacion",
                    {
                        type: "success",
                        placement: 'top',
                        animationType: 'zoom-in',
                        style: {
                            borderRadius: 15,
                        },
                    });

                setTypeForm(!typeForm);
                navigation.navigate('Home');

            } catch (error: any) {
                toast.show(error.message,
                    {
                        type: "danger",
                        placement: 'top',
                        animationType: 'zoom-in',
                        style: {
                            borderRadius: 15,
                        },

                    })

            }
            setLoading(false);
        },
        validate: (values) => {
            let errors: any = {};
            if (!values.name)
                errors.name = 'El nombre es requerido';

            if (!values.lastName)
                errors.lastName = 'El apellido es requerido';

            if (!values.email)
                errors.email = 'El email es requerido';

            if (!values.password)
                errors.password = 'La contraseña es requerida';

            return errors;
        }
    })

    return (
        <Card className="mt-3" style={{ width: "80%" }}>
            <Card.Content>

                <View className="grid grid-cols-2">

                    <Input
                        label="Nombre"
                        placeholder="Ej: John"
                        value={signupFormik.values.name}
                        error={signupFormik.errors.name}
                        existError={!!signupFormik.errors.name}
                        onChange={(e: any) => {
                            signupFormik.setFieldValue('name', e.nativeEvent.text);
                        }}
                        onEnter={() => {
                            signupFormik.handleSubmit();
                        }}
                    />

                    <Input
                        label="Apellido"
                        placeholder="Ej: Marck"
                        value={signupFormik.values.lastName}
                        error={signupFormik.errors.lastName}
                        existError={!!signupFormik.errors.lastName}
                        onChange={(e: any) => {
                            signupFormik.setFieldValue('lastName', e.nativeEvent.text);
                        }}
                        onEnter={() => {
                            signupFormik.handleSubmit();
                        }}
                    />

                    <Input
                        label="Email"
                        placeholder="Ej: example@eleti.dev"
                        value={signupFormik.values.email}
                        error={signupFormik.errors.email}
                        existError={!!signupFormik.errors.email}
                        onChange={(e: any) => {
                            signupFormik.setFieldValue('email', e.nativeEvent.text);
                        }}
                        onEnter={() => {
                            signupFormik.handleSubmit();
                        }}
                    />


                    <Input
                        label="Contraseña"
                        placeholder="Sectret123"
                        value={signupFormik.values.password}
                        error={signupFormik.errors.password}
                        existError={!!signupFormik.errors.password}
                        onChange={(e: any) => {
                            signupFormik.setFieldValue('password', e.nativeEvent.text);
                        }}
                        secureTextEntry
                        onEnter={() => {
                            signupFormik.handleSubmit();
                        }}
                    />

                </View>

                <Button
                    mode="contained"
                    onPress={() => {
                        signupFormik.handleSubmit();
                    }}
                    className="m-3"
                    loading={loading}
                    disabled={loading}
                >
                    Crear cuenta
                </Button>

                {/* sino tienes cuenta registrate */}
                <View className="flex flex-row justify-center items-center">
                    <TextPaper className="text-base">¿Ya tienes cuenta?</TextPaper>
                    <Button
                        mode="contained-tonal"
                        onPress={() => {
                            setTypeForm(!typeForm);
                        }}
                        className="m-3"
                    >
                        Iniciar sesion
                    </Button>
                </View>
            </Card.Content>
        </Card>
    )
}