import { useFormik } from "formik";
import { StyleSheet, View, Text, TextInput, ScrollView } from "react-native";
import { Image } from "react-native";
import { Button, TouchableRipple } from "react-native-paper";
import { useEffect, useState } from "react";
import { DropdownComponent } from "../../../components/forms/DropdownComponent";
import { useMutation, useQuery } from "@apollo/client";
import { GET_INVITATIONS_AND_USERS_BY_GROUP, GET_INVITATIONS_BY_GROUP, GET_ROLES, GET_USER_PERMISSIONS } from "../graphql/query";
import { CREATE_INVITATION, DELETE_INVITATION, REMOVE_USER_FROM_GROUP, RESEND_INVITATION, UPDATE_INVITATION, UPDATE_USER_GROUP } from "../graphql/mutation";
import ModalLayout from "../../../components/ui/Modal";
import AlertDialog from "../../../components/ui/AlertDialog";
import React from "react";

interface TableApiKeysProps {
    toast: any;
    groupId: string;
    permissions: any;
}


export default function TableMembers({ toast, groupId, permissions }: TableApiKeysProps) {

    const [roles, setRoles] = useState([]);
    const [members, setMembers] = useState([]);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [selectedMember, setSelectedMember] = useState({} as any);

    const { data: dataRoles, loading: loadingRoles } = useQuery(GET_ROLES);
    const { data: dataMembers, loading: loadingMembers } = useQuery(GET_INVITATIONS_AND_USERS_BY_GROUP);
    const [createInvitation, { data: dataCreateInvitation, loading: loadingCreateInvitation, error: errorCreateInvitation }] = useMutation(CREATE_INVITATION,
        {
            refetchQueries: [
                {
                    query: GET_INVITATIONS_AND_USERS_BY_GROUP,
                    variables: {
                        groupId
                    }
                },
            ],

        });
    const [resendInvitation, { data: dataResendInvitation, loading: loadingResendInvitation, error: errorResendInvitation }] = useMutation(RESEND_INVITATION,
        {
            refetchQueries: [
                {
                    query: GET_INVITATIONS_AND_USERS_BY_GROUP,
                    variables: {
                        groupId
                    }
                },
            ],

        });
    const [updateInvitation, { data: dataUpdateInvitation, loading: loadingUpdateInvitation, error: errorUpdateInvitation }] = useMutation(UPDATE_INVITATION,
        {
            refetchQueries: [
                {
                    query: GET_INVITATIONS_BY_GROUP,
                    variables: {
                        groupId
                    }
                },
            ],

        });
    const [removeUserFromGroup, { data: dataRemoveUserFromGroup, loading: loadingRemoveUserFromGroup, error: errorRemoveUserFromGroup }] = useMutation(REMOVE_USER_FROM_GROUP,
        {
            refetchQueries: [
                {
                    query: GET_INVITATIONS_AND_USERS_BY_GROUP,
                },
            ],

        });
    const [deleteInvitation, { data: dataDeleteInvitation, loading: loadingDeleteInvitation, error: errorDeleteInvitation }] = useMutation(DELETE_INVITATION,
        {
            refetchQueries: [
                {
                    query: GET_INVITATIONS_AND_USERS_BY_GROUP,
                },
            ],

        });
    const [updateUserGroup, { data: dataUpdateUserGroup, loading: loadingUpdateUserGroup, error: errorUpdateUserGroup }] = useMutation(UPDATE_USER_GROUP,
        {
            refetchQueries: [
                {
                    query: GET_INVITATIONS_AND_USERS_BY_GROUP,
                },
                {
                    query: GET_USER_PERMISSIONS,
                }
            ],

        });

    const styles = StyleSheet.create({
        input: {
            padding: 10,
            flex: 1, // Para que cada input ocupe el espacio que le corresponde
            borderRightWidth: 1, // Añadimos un borde a la derecha de cada input
            borderColor: "#D9D9D9", // Color del borde
        },
        headerText: {
            display: "flex",
            fontWeight: "bold",
            color: "#27374D", // Color del texto
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#D9D9D9",
            padding: 3
        },

        headerTextRadiusLeft: {
            display: "flex",
            fontWeight: "bold",
            color: "#27374D", // Color del texto
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            borderTopLeftRadius: 40,
            backgroundColor: "#D9D9D9",
            padding: 3

        },
        headerTextRadiusRight: {
            display: "flex",
            fontWeight: "bold",
            color: "#27374D", // Color del texto
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            borderTopRightRadius: 40,
            backgroundColor: "#D9D9D9",
            padding: 3

        }
    });


    const handleOnChage = async (item: any) => {
        invitationFormik.setFieldValue("rol", item.value);
    }

    const handleResendInvitation = async (item: any) => {

        const { data } = await resendInvitation({
            variables: {
                invitationId: item.id
            }
        });

        toast.show(data.resendInvitation.message, {
            type: data.resendInvitation.success ? "success" : "danger",
            placement: "top",
            animationType: "zoom-in",
            style: {
                borderRadius: 15,
            },
        });


    }

    const handleSaveInvitation = async (item: any) => {

        if (item.type === "invitation") {
            const { data } = await updateInvitation({
                variables: {
                    invitationId: item.id,
                    email: item.email,
                    name: item.name,
                    roleId: item.role.id || item.role.value
                }
            });

            if (item.resend) {
                await resendInvitation({
                    variables: {
                        invitationId: item.id
                    }
                });
            }


            toast.show(data.updateInvitation.message, {
                type: data.updateInvitation.success ? "success" : "danger",
                placement: "top",
                animationType: "zoom-in",
                style: {
                    borderRadius: 15,
                },
            });
        } else if (item.type === "user") {
            // const { data } = await updateInvitation({
            //     variables: {
            //         userId: item.id,
            //         email: item.email,
            //         name: item.name,
            //         roleId: item.role.id || item.role.value
            //     }
            // });

            // toast.show(data.updateInvitation.message, {
            //     type: data.updateInvitation.success ? "success" : "danger",
            //     placement: "top",
            //     animationType: "zoom-in",
            // });
        }



    }

    const handleUpdateUser = async (item: any) => {

        const { data } = await updateUserGroup({
            variables: {
                userId: item.id,
                roleId: item.role.id || item.role.value
            }
        });

        toast.show(data.updateUserGroup.message, {
            type: data.updateUserGroup.success ? "success" : "danger",
            placement: "top",
            animationType: "zoom-in",
            style: {
                borderRadius: 15,
            },
        });

    }

    const onClickDelete = (item: any) => {
        setSelectedMember(item);
        setShowModalDelete(true);
    }

    const handleDelete = async () => {

        // eliminar usuario
        if (selectedMember.type === "user") {
            const { data } = await removeUserFromGroup({
                variables: {
                    userId: selectedMember.id
                }
            });

            toast.show(data.removeUserFromGroup.message, {
                type: data.removeUserFromGroup.success ? "success" : "danger",
                placement: "top",
                animationType: "zoom-in",
                style: {
                    borderRadius: 15,
                },
            });
        } else if (selectedMember.type === "invitation") {
            const { data } = await deleteInvitation({
                variables: {
                    invitationId: selectedMember.id
                }
            });

            toast.show(data.deleteInvitation.message, {
                type: data.deleteInvitation.success ? "success" : "danger",
                placement: "top",
                animationType: "zoom-in",
                style: {
                    borderRadius: 15,
                },
            });
        }

        setShowModalDelete(false);

    }

    const invitationFormik = useFormik({
        initialValues: {
            email: "",
            name: "",
            rol: "",
        },
        validate: (values) => {
            const errors: any = {};

            if (!values.email) {
                errors.email = "El correo es requerido";
            }

            if (!values.name) {
                errors.name = "El nombre es requerido";
            }

            if (!values.rol) {
                errors.rol = "El rol es requerido";
            }

            return errors;
        },
        onSubmit: async (values) => {

            const { data } = await createInvitation({
                variables: {
                    name: values.name,
                    email: values.email,
                    groupId,
                    roleId: values.rol
                }
            });

            toast.show(data.createInvitation.message, {
                type: data.createInvitation.success ? "success" : "danger",
                placement: "top",
                animationType: "zoom-in",
                style: {
                    borderRadius: 15,
                },
            });

            if (data.createInvitation.success)
                invitationFormik.resetForm();

        },
    });

    useEffect(() => {

        if (!loadingMembers && dataMembers) {

            const users = dataMembers.invitationsUsersByGroup.users.map((user: any) => ({
                id: user.id,
                email: user.email,
                name: user.name,
                status: 1,
                type: "user",
                role: {
                    label: user.role.label,
                    id: user.role.id
                }
            }));

            const invitations = dataMembers.invitationsUsersByGroup.invitations.map((invitation: any) => ({
                id: invitation.id,
                email: invitation.email,
                name: invitation.name,
                status: invitation.status,
                type: "invitation",
                role: {
                    label: invitation.role.label,
                    id: invitation.role.id
                }
            }));

            setMembers(users.concat(invitations));
        }

        if (dataRoles) {
            const roles = dataRoles.roles.map((role: any) => ({
                label: role.label,
                value: role.id,
            }));

            setRoles(roles);
        }


    }, [dataMembers]);


    return (
        <ScrollView horizontal className="w-[100%]">
            <View
                style={{ borderTopLeftRadius: 40, borderTopRightRadius: 40 }}
                className="w-[100%]"
            >
                <View className="flex flex-col w-[100%]">

                    <View className="grid grid-cols-members w-[auto] md:w-[75vw]">

                        {/* header */}
                        <Text style={styles.headerTextRadiusLeft}>Correo</Text>
                        <Text style={styles.headerText}>Nombre</Text>
                        <Text style={styles.headerText}>Estatus</Text>
                        <Text style={styles.headerText}>Rol</Text>
                        <Text style={styles.headerText}>Guardar</Text>
                        <Text style={styles.headerTextRadiusRight}>Borrar</Text>

                        {
                            !loadingMembers && (
                                <>

                                    {
                                        members.map((item: any, index: any) => (
                                            < >
                                                <TextInput
                                                    style={styles.input}
                                                    value={item.email}
                                                    className={` bg-[#27374D] border-b text-[#D9D9D9] `}
                                                    onChangeText={(text) => {

                                                        if (item.type === "user" && item.status === 1) return;

                                                        const newInvitations: any = members.map((invitation: any) => ({
                                                            ...invitation
                                                        }));

                                                        newInvitations[index].email = text;
                                                        newInvitations[index].resend = true;

                                                        setMembers(newInvitations);

                                                    }}
                                                />

                                                <TextInput
                                                    style={styles.input}
                                                    value={item.name}
                                                    className={` bg-[#27374D] border-b text-[#D9D9D9] `}
                                                    onChangeText={(text) => {

                                                        if (item.type === "user" && item.status === 1) return;

                                                        const newInvitations: any = members.map((invitation: any) => ({
                                                            ...invitation
                                                        }));

                                                        newInvitations[index].name = text;

                                                        setMembers(newInvitations);

                                                    }}
                                                />

                                                <View className="flex pl-3 flex-row items-center  bg-[#27374D] border-r border-b border-[#D9D9D9]">

                                                    {
                                                        item.status === 0 && (
                                                            <>
                                                                <Image
                                                                    source={require('../../../../assets/error.svg')}
                                                                    style={{ width: 20, height: 20 }}
                                                                    tintColor={"#C4841D"}
                                                                />

                                                                <Text className="text-[#D9D9D9] pl-2"> Pendiente </Text>
                                                            </>
                                                        )
                                                    }

                                                    {
                                                        item.status === 1 && (
                                                            <>
                                                                <Image
                                                                    source={require('../../../../assets/success.svg')}
                                                                    style={{ width: 20, height: 20 }}
                                                                    tintColor={"#36AE7C"}
                                                                />

                                                                <Text className="text-[#D9D9D9] pl-2"> Activo </Text>
                                                            </>
                                                        )
                                                    }

                                                    {
                                                        item.status === 2 && (
                                                            <>
                                                                <Image
                                                                    source={require('../../../../assets/error.svg')}
                                                                    style={{ width: 20, height: 20 }}
                                                                    tintColor={"#FF6666"}
                                                                />

                                                                <Text className="text-[#D9D9D9] pl-2"> Inactivo </Text>
                                                            </>
                                                        )
                                                    }

                                                    {
                                                        item.status === 3 && (
                                                            <>
                                                                <Image
                                                                    source={require('../../../../assets/warn.svg')}
                                                                    style={{ width: 20, height: 20 }}
                                                                    tintColor={"#FF6666"}
                                                                />

                                                                <Text className="text-[#D9D9D9] pl-2"> Rechazado </Text>
                                                            </>
                                                        )
                                                    }

                                                </View>

                                                <View className="flex justify-center bg-[#27374D] border-r border-b border-[#D9D9D9] text-[#D9D9D9]">
                                                    <DropdownComponent
                                                        data={roles}
                                                        onChange={(role: any) => {

                                                            const newInitations: any = members.map((apiKey: any) => ({
                                                                ...apiKey
                                                            }));

                                                            newInitations[index].role = role;
                                                            newInitations[index].resend = true;

                                                            setMembers(newInitations);
                                                        }}
                                                        placeholder={item.role.label}
                                                    />
                                                </View>

                                                <View className="flex justify-center items-center flex-row bg-[#27374D] border-r border-b border-[#D9D9D9]">


                                                    {

                                                        permissions?.update && (

                                                            loadingResendInvitation || loadingUpdateInvitation || loadingUpdateUserGroup ? (
                                                                <View className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white" />
                                                            ) : (
                                                                <Button
                                                                    mode="text"
                                                                    onPress={
                                                                        () => {
                                                                            if (item.type === "invitation") {
                                                                                if (item.status === 2 || item.status === 3) {
                                                                                    handleResendInvitation(item);
                                                                                } else {
                                                                                    handleSaveInvitation(item);
                                                                                }

                                                                            } else {
                                                                                // editamos el rol del usuario
                                                                                handleUpdateUser(item);

                                                                            }
                                                                        }}

                                                                    className="hover:transform hover:scale-110 transition duration-100 ease-in-out"
                                                                >
                                                                    <TouchableRipple
                                                                        onPress={() => {
                                                                            if (item.type === "invitation") {
                                                                                if (item.status === 2 || item.status === 3) {
                                                                                    handleResendInvitation(item);
                                                                                } else {
                                                                                    handleSaveInvitation(item);
                                                                                }

                                                                            } else {
                                                                                // editamos el rol del usuario
                                                                                handleUpdateUser(item);
                                                                            }
                                                                        }}
                                                                    >
                                                                        <Image
                                                                            source={(item.status === 2 || item.status === 3 || item?.resend) && item.type === "invitation" ? require('../../../../assets/send.svg') : require('../../../../assets/save.svg')}
                                                                            style={{ width: 20, height: 20 }}
                                                                            tintColor={"#D9D9D9"}
                                                                        />
                                                                    </TouchableRipple>
                                                                </Button>
                                                            )
                                                        )

                                                    }


                                                </View >

                                                <View className="flex justify-center items-center flex-row bg-[#27374D] border-b border-[#D9D9D9]">

                                                    {

                                                        permissions?.delete && (

                                                            loadingRemoveUserFromGroup || loadingDeleteInvitation ? (
                                                                <View className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white" />
                                                            ) : (
                                                                <Button
                                                                    mode="text"
                                                                    onPress={() => { onClickDelete(item) }}

                                                                    className="hover:transform hover:scale-110 transition duration-100 ease-in-out"
                                                                >
                                                                    <TouchableRipple
                                                                        onPress={() => { onClickDelete(item) }}
                                                                    >
                                                                        <Image
                                                                            source={require('../../../../assets/delete.svg')}
                                                                            style={{ width: 20, height: 20 }}
                                                                            tintColor={"#D9D9D9"}
                                                                        />
                                                                    </TouchableRipple>
                                                                </Button>
                                                            )
                                                        )

                                                    }


                                                </View>


                                            </>
                                        ))
                                    }

                                    {
                                        permissions?.create && (
                                            <>
                                                <TextInput
                                                    style={styles.input}
                                                    placeholder="Correo"
                                                    value={invitationFormik.values.email}
                                                    onChangeText={invitationFormik.handleChange('email')}
                                                    className="bg-[#27374D] border-r border-[#D9D9D9] text-[#D9D9D9]"
                                                />

                                                <TextInput
                                                    style={styles.input}
                                                    placeholder="Nombre"
                                                    value={invitationFormik.values.name}
                                                    onChangeText={invitationFormik.handleChange('name')}
                                                    className="bg-[#27374D] border-r border-[#D9D9D9] text-[#D9D9D9]"
                                                />

                                                <View className="flex items-center justify-center bg-[#27374D] border-r border-[#D9D9D9] text-[#D9D9D9]">
                                                </View>

                                                <View className="flex items-center justify-center bg-[#27374D] border-r border-[#D9D9D9] text-[#D9D9D9]">
                                                    <DropdownComponent
                                                        data={roles}
                                                        onChange={handleOnChage}
                                                    />
                                                </View>

                                                <View className="flex justify-center items-center flex-row bg-[#27374D] border-r border-[#D9D9D9] ">

                                                    {
                                                        loadingCreateInvitation ? (
                                                            <View className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white" />
                                                        ) : (
                                                            Object.keys(invitationFormik.errors).length === 0 && (
                                                                <Button
                                                                    mode="text"
                                                                    onPress={invitationFormik.handleSubmit}
                                                                    className="hover:transform transition duration-100 ease-in-out"
                                                                >
                                                                    <TouchableRipple
                                                                        onPress={invitationFormik.handleSubmit}
                                                                    >
                                                                        <Image
                                                                            source={require('../../../../assets/send.svg')}
                                                                            style={{ width: 20, height: 20 }}
                                                                            tintColor={"#D9D9D9"}
                                                                        />
                                                                    </TouchableRipple>
                                                                </Button>
                                                            )
                                                        )
                                                    }

                                                </View>

                                                <View className="flex items-center justify-center bg-[#27374D] border-[#D9D9D9] text-[#D9D9D9]">
                                                </View>
                                            </>
                                        )
                                    }

                                </>

                            )
                        }

                    </View>

                </View>
            </View>

            <AlertDialog
                showDialog={showModalDelete}
                setShowDialog={setShowModalDelete}
                OnClickAction={handleDelete}
                text={`Eliminar este elemento es irreversible. ¿Quieres continuar?`}
                loading={loadingDeleteInvitation || loadingRemoveUserFromGroup}
            />

        </ScrollView >
    )

}