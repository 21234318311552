
import React from 'react';
import ValidateNumber from './ValidateNumber';
import Chat from './Chat';

export default function App({ route, navigation, validateEmail }: any) {

    return (

        <>
            {
                !validateEmail ? (
                    <ValidateNumber />
                ) : (
                    <Chat
                        navigation={navigation}
                        route={route}
                    />
                )
            }
        </>

    )
}