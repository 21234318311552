import { View } from "react-native";


export default function LoaderInput() {

    return (
        // animacion al aparecer la pantalla de carga
        <View className="flex items-center justify-center fixed bottom-0 w-full m-5">
            <View className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white" />
        </View>
    )
}