import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
    apiKey: "AIzaSyD_zo1uCnEzrwbh6mxVThODXWvX-9DFaM4",
    authDomain: "eleti-dev.firebaseapp.com",
    projectId: "eleti-dev",
    storageBucket: "eleti-dev.appspot.com",
    messagingSenderId: "343243640083",
    appId: "1:343243640083:web:e9e956fc9f47cd85f4dee6"
};

const app = initializeApp(firebaseConfig)
export const auth = getAuth()
