import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { StyleSheet, View, Text, TextInput, ScrollView } from "react-native";
import { Image } from "react-native";
import { Button, TouchableRipple } from "react-native-paper";
import AlertDialog from "../../../components/ui/AlertDialog";
import React from "react";

interface TableGroupsProps {
    createGroup: any;
    toast: any;
    loadingGroups: boolean;
    groups: any;
    setGroups: any;
    loadingUpdateGroup: boolean;
    loadingActiveGroup: boolean;
    dataActive: any;
    handleActivateGroup: any;
    handleEditButton: any;
    loadingCreateGroup: boolean;
    deleteGroup: any;
    loadingDeleteGroup: boolean;
    permissions: any;
}


export default function TableGroups({ loadingDeleteGroup, deleteGroup, createGroup, toast, loadingGroups, groups, setGroups, loadingUpdateGroup, loadingActiveGroup, dataActive, handleActivateGroup, handleEditButton, loadingCreateGroup, permissions }: TableGroupsProps) {

    const [showDialogDelete, setShowDialogDelete] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState({} as any);

    const styles = StyleSheet.create({
        input: {
            padding: 10,
            flex: 1, // Para que cada input ocupe el espacio que le corresponde
            borderRightWidth: 1, // Añadimos un borde a la derecha de cada input
            borderColor: "#D9D9D9", // Color del borde
        },
        headerText: {
            display: "flex",
            fontWeight: "bold",
            color: "#27374D", // Color del texto
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#D9D9D9",
            padding: 3
        },

        headerTextRadiusLeft: {
            display: "flex",
            fontWeight: "bold",
            color: "#27374D", // Color del texto
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            borderTopLeftRadius: 40,
            backgroundColor: "#D9D9D9",
            padding: 3

        },
        headerTextRadiusRight: {
            display: "flex",
            fontWeight: "bold",
            color: "#27374D", // Color del texto
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            borderTopRightRadius: 40,
            backgroundColor: "#D9D9D9",
            padding: 3

        }
    });

    const newGroupFormik = useFormik({
        initialValues: {
            name: '',
        },
        validate: values => {
            const errors: any = {};
            if (!values.name) {
                errors.nombre = 'Required';
            }

            return errors;
        },
        onSubmit: async (values) => {
            try {
                const { data } = await createGroup({
                    variables: {
                        name: values.name,
                        description: 'hola',
                    }
                })

                toast.show(data.createGroup.message,
                    {
                        type: data.createGroup.success ? "success" : "danger",
                        placement: 'top',
                        animationType: 'zoom-in',
                        style: {
                            borderRadius: 15,
                        },
                    })

                newGroupFormik.resetForm();
            } catch (error) {
                console.log(error);

            }
        },
    });

    const handleSaveButton = () => {
        newGroupFormik.handleSubmit();
    }

    const handleDeleteButton = async () => {
        const { data } = await deleteGroup({
            variables: {
                groupId: selectedGroup.id
            }
        })

        toast.show(data.deleteGroup.message,
            {
                type: "success",
                placement: 'top',
                animationType: 'zoom-in',
                style: {
                    borderRadius: 15,
                },
            })

        setShowDialogDelete(false);

    }

    const handleOnCLickDelete = (item: any) => {
        setSelectedGroup(item);
        setShowDialogDelete(true);
    }


    return (
        <ScrollView horizontal className="w-[100%] ">
            <View
                style={{ borderTopLeftRadius: 40, borderTopRightRadius: 40 }}
                className="w-[100%]"
            >
                <View className="flex flex-col w-[100%]">

                    <View className="grid grid-cols-groups w-[auto] md:w-[75vw]" >

                        {/* header */}
                        <Text style={styles.headerTextRadiusLeft} numberOfLines={1}>Nombre</Text>
                        {/* <Text style={styles.headerText}>Grupos</Text> */}
                        <Text style={styles.headerText} numberOfLines={1}>Propietario</Text>
                        <Text style={styles.headerText} numberOfLines={1}>Miembros</Text>
                        <Text style={styles.headerText} numberOfLines={1}>Suscripción</Text>
                        <Text style={styles.headerText} numberOfLines={1}>Administrar</Text>
                        <Text style={styles.headerText} numberOfLines={1}>Guardar</Text>
                        <Text style={styles.headerTextRadiusRight} numberOfLines={1}>Borrar</Text>

                        {
                            !loadingGroups && (
                                <>

                                    {
                                        groups.map((item: any, index: any) => (
                                            < >
                                                <TextInput
                                                    style={styles.input}
                                                    value={item.name}
                                                    className={`${dataActive?.groupActive?.id == item.id ? "font-bold" : ""} bg-[#27374D] border-b text-[#D9D9D9] `}
                                                    onChangeText={(text) => {
                                                        const newGroups: any = groups.map((group: any) => ({
                                                            ...group
                                                        }));

                                                        newGroups[index].name = text;
                                                        setGroups(newGroups);

                                                    }}
                                                />

                                                <View className="flex pl-3 justify-center bg-[#27374D] border-r border-b border-[#D9D9D9]">
                                                    <Text
                                                        className="text-[#D9D9D9]"
                                                    > {item.owner?.name ? item.owner?.name : 'Sin propietario'} </Text>
                                                </View>

                                                <View className="flex pl-3 justify-center bg-[#27374D] border-r border-b border-[#D9D9D9]">
                                                    <Text
                                                        className="text-[#D9D9D9]"
                                                    >
                                                        {
                                                            item.users.map((user: any, index: number) => (
                                                                <Text key={user.id} className="text-[#D9D9D9]">{user.name}{index < item.users.length - 1 ? ', ' : ''}</Text>
                                                            ))
                                                        }
                                                    </Text>
                                                </View>

                                                <View className="flex flex-row pl-3 justify-between items-center bg-[#27374D] border-r border-b border-[#D9D9D9]">
                                                    <Text
                                                        className="text-[#D9D9D9] mb-1"
                                                    >
                                                        {item.subscription?.plan?.name}
                                                    </Text>

                                                    <Button
                                                        mode="text"
                                                        className="hover:transform hover:scale-110 transition duration-100 ease-in-out"
                                                    >
                                                        <TouchableRipple
                                                        >
                                                            <Image
                                                                source={require('../../../../assets/send.svg')}
                                                                style={{ width: 20, height: 20 }}
                                                                tintColor={"#D9D9D9"}
                                                            />
                                                        </TouchableRipple>
                                                    </Button>
                                                </View>

                                                <View className="flex justify-center items-center flex-row bg-[#27374D] border-r border-b border-[#D9D9D9]">

                                                    {
                                                        loadingActiveGroup ? (
                                                            <View className="flex items-center justify-center bg-[#27374D] border-[#D9D9D9] text-[#D9D9D9]">
                                                                <View className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white" />
                                                            </View>
                                                        ) : (
                                                            <Button
                                                                mode="text"
                                                                onPress={() => { handleActivateGroup(item) }}

                                                                className="hover:transform hover:scale-110 transition duration-100 ease-in-out"
                                                            >
                                                                <TouchableRipple
                                                                    onPress={() => { handleActivateGroup(item) }}
                                                                >
                                                                    <Image
                                                                        source={require('../../../../assets/send.svg')}
                                                                        style={{ width: 20, height: 20 }}
                                                                        tintColor={"#D9D9D9"}
                                                                    />
                                                                </TouchableRipple>
                                                            </Button>
                                                        )
                                                    }

                                                </View>

                                                <View className="flex justify-center items-center flex-row bg-[#27374D] border-r border-b border-[#D9D9D9]">

                                                    {

                                                        dataActive?.groupActive?.id == item.id ? (

                                                            permissions?.update && (
                                                                loadingUpdateGroup ? (
                                                                    <View className="flex items-center justify-center bg-[#27374D] border-[#D9D9D9] text-[#D9D9D9]">
                                                                        <View className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white" />
                                                                    </View>
                                                                ) : (
                                                                    <Button
                                                                        mode="text"
                                                                        onPress={() => { handleEditButton(item) }}
                                                                        className="hover:transform hover:scale-110 transition duration-100 ease-in-out"
                                                                    >
                                                                        <TouchableRipple
                                                                            onPress={() => { handleEditButton(item) }}
                                                                        >
                                                                            <Image
                                                                                source={require('../../../../assets/save.svg')}
                                                                                style={{ width: 20, height: 20 }}
                                                                                tintColor={"#D9D9D9"}
                                                                            />
                                                                        </TouchableRipple>
                                                                    </Button>
                                                                )
                                                            )
                                                        ) : (
                                                            <View className="flex items-center justify-center bg-[#27374D] border-[#D9D9D9] text-[#D9D9D9]">

                                                            </View>
                                                        )

                                                    }

                                                </View>

                                                <View className="flex justify-center items-center flex-row border-b border-[#D9D9D9] bg-[#27374D]">

                                                    {
                                                        dataActive?.groupActive?.id == item.id ? (

                                                            permissions?.delete && (
                                                                loadingDeleteGroup ? (
                                                                    <View className="flex items-center justify-center bg-[#27374D] border-[#D9D9D9] text-[#D9D9D9]">
                                                                        <View className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white" />
                                                                    </View>
                                                                ) : (
                                                                    <Button
                                                                        mode="text"
                                                                        onPress={() => { handleOnCLickDelete(item) }}
                                                                        className="hover:transform hover:scale-110 transition duration-100 ease-in-out"
                                                                    >
                                                                        <TouchableRipple
                                                                            onPress={() => { handleOnCLickDelete(item) }}
                                                                        >
                                                                            <Image
                                                                                source={require('../../../../assets/delete.svg')}
                                                                                style={{ width: 20, height: 20 }}
                                                                                tintColor={"#D9D9D9"}
                                                                            />
                                                                        </TouchableRipple>
                                                                    </Button>
                                                                )
                                                            )
                                                        ) : (
                                                            <View className="flex items-center justify-center bg-[#27374D] border-[#D9D9D9] text-[#D9D9D9]">

                                                            </View>
                                                        )

                                                    }

                                                </View>

                                            </>
                                        ))
                                    }

                                    <TextInput
                                        style={styles.input}
                                        placeholder="Nombre del grupo"
                                        value={newGroupFormik.values.name}
                                        onChangeText={newGroupFormik.handleChange('name')}
                                        className="bg-[#27374D] border-r border-[#D9D9D9] text-[#D9D9D9]"
                                    />

                                    <View className="flex items-center justify-center bg-[#27374D] border-r border-[#D9D9D9] text-[#D9D9D9]">
                                        <Text>  </Text>
                                    </View>

                                    <View className="flex items-center justify-center bg-[#27374D] border-r border-[#D9D9D9] text-[#D9D9D9]">
                                        <Text className="">  </Text>
                                    </View>

                                    <View className="flex items-center justify-center bg-[#27374D] border-r border-[#D9D9D9] text-[#D9D9D9]">
                                        <Text>  </Text>
                                    </View>

                                    <View className="flex items-center justify-center bg-[#27374D] border-r border-[#D9D9D9] text-[#D9D9D9]">
                                        <Text>  </Text>
                                    </View>

                                    {
                                        loadingCreateGroup ? (
                                            <View className="flex items-center justify-center bg-[#27374D] border-r border-[#D9D9D9] text-[#D9D9D9]">
                                                <View className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white" />
                                            </View>
                                        ) : (
                                            <View className="flex justify-center items-center flex-row bg-[#27374D] border-r border-[#D9D9D9] ">
                                                {
                                                    newGroupFormik.values.name.length > 0 && (
                                                        <Button
                                                            mode="text"
                                                            onPress={handleSaveButton}
                                                            className="hover:transform transition duration-100 ease-in-out"
                                                        >
                                                            <TouchableRipple
                                                                onPress={handleSaveButton}
                                                            >
                                                                <Image
                                                                    source={require('../../../../assets/send.svg')}
                                                                    style={{ width: 20, height: 20 }}
                                                                    tintColor={"#D9D9D9"}
                                                                />
                                                            </TouchableRipple>
                                                        </Button>
                                                    )
                                                }


                                            </View>
                                        )
                                    }

                                    <View className="flex items-center justify-center bg-[#27374D] border-[#D9D9D9] text-[#D9D9D9]">
                                        <Text>  </Text>
                                    </View>

                                </>

                            )
                        }

                    </View>

                </View>
            </View>

            <AlertDialog
                showDialog={showDialogDelete}
                setShowDialog={setShowDialogDelete}
                OnClickAction={handleDeleteButton}
                text={`Eliminar este elemento es irreversible. ¿Quieres continuar?`}
                loading={loadingDeleteGroup}
            />
        </ScrollView>
    )

}