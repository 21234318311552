import { View } from 'react-native';
import { Card, Text as TextPaper } from 'react-native-paper';
import { Button } from 'react-native-paper';
import { useFormik } from "formik";
import { useContext, useState } from 'react';
import { useToast } from "react-native-toast-notifications";
import Input from '../../../components/forms/Input';

import UserContext from "../../../../context/user/UserContext";

interface LoginFormProps {
    navigation: any;
    setTypeForm: any;
    typeForm: boolean;
    setRecoveryPassword: any;
}

export default function LoginForm({ navigation, setTypeForm, typeForm, setRecoveryPassword }: LoginFormProps) {

    const { login }: any = useContext(UserContext);

    const [loading, setLoading] = useState(false);

    const toast = useToast();

    const handleLogin = () => {
        formikLogin.handleSubmit();
    }

    const formikLogin = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        onSubmit: async (values) => {
            setLoading(true);
            try {
                await login(values.email, values.password);
                
                navigation.navigate('Home');

            } catch (error) {
                toast.show("Ocurrio un error al iniciar sesion, verifica tus credenciales",
                    {
                        type: "danger",
                        placement: 'top',
                        animationType: 'zoom-in',
                        style: {
                            borderRadius: 15,
                        },
                    })
            }
            setLoading(false);
        },
        validate: (values) => {
            let errors: any = {};
            if (!values.email) {
                errors.email = 'El email es requerido';
            }
            if (!values.password) {
                errors.password = 'La contraseña es requerida';
            }
            return errors;
        }
    })

    return (
        <Card className="mt-3 w-4/5 md:w-3/5" style={{ backgroundColor: "#526D82" }}>
            <Card.Content>

                <Input
                    label='Email'
                    testID='input-email'
                    placeholder='Ej: example@eleti.dev'
                    error={formikLogin.errors.email || ''}
                    existError={formikLogin.errors.email ? true : false}
                    value={formikLogin.values.email}
                    onChange={(e: any) => {
                        formikLogin.setFieldValue('email', e.nativeEvent.text);
                    }}
                    onEnter={handleLogin}
                />

                <Input
                    label='Contraseña'
                    testID='input-password'
                    placeholder='Secret123'
                    error={formikLogin.errors.password}
                    existError={formikLogin.errors.password ? true : false}
                    value={formikLogin.values.password}
                    secureTextEntry
                    onChange={(e: any) => {
                        formikLogin.setFieldValue('password', e.nativeEvent.text);
                    }}
                    onEnter={handleLogin}
                />

                <Button
                    mode="contained"
                    testID='login-button'
                    onPress={handleLogin}
                    className="m-3"
                    loading={loading}
                >
                    Iniciar sesion
                </Button>

                {/* sino tienes cuenta registrate */}
                <View className="flex flex-row justify-center items-center">
                    <TextPaper className="text-base">¿No tienes cuenta?</TextPaper>
                    <Button
                        mode="contained-tonal"
                        onPress={() => {
                            setTypeForm(!typeForm);
                        }}
                        className="m-3"
                    >
                        Registrate
                    </Button>
                </View>

                {/* recuperar contraseña */}
                <View className="flex flex-row justify-center items-center">
                    <Button
                        mode="text"
                        onPress={() => {
                            setRecoveryPassword(true);
                        }}
                        className="text-white"
                    >
                        ¿Olvidaste tu contraseña?
                    </Button>
                </View>
            </Card.Content>
        </Card>
    )
}