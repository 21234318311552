import Layout from "../layout/Main";
import { View, Text, ScrollView, Image } from "react-native";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { GET_TEMPLATES, GET_ACTIVE_GROUP } from "./graphql/query";
import { Button, Tooltip, TouchableRipple } from "react-native-paper";
import { useContext, useEffect, useState } from "react";
import TemplateForm from "./components/TemplateForm";
import SearchTemplateInput from "../../components/ui/SearchTemplateInput";
import { CREATE_TEMPLATE, DELETE_TEMPLATE, UPDATE_TEMPLATE } from "./graphql/mutation";
import React from "react";
import AlertDialog from "../../components/ui/AlertDialog";
import { Dimensions } from 'react-native';
import UserContext from "../../../context/user/UserContext";
import Loader from "../../components/ui/Loader";
import { formatInitial } from "../../utils/formater";
import { Tooltip as TTool } from "@material-tailwind/react";

export default function MainTemplates({ navigation }: any) {

    const { userState } = useContext(UserContext);

    const [showForm, setShowForm] = useState(false);
    const [edit, setEdit] = useState(false);
    const [group, setGroup] = useState({} as any);
    const [templates, setTemplates] = useState([] as any);
    const [templateSelected, setTemplateSelected] = useState({} as any);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [isHeight, setIsHeight] = useState(false);

    const { loading: loadingActiveGroup, data: dataActiveGroup } = useQuery(GET_ACTIVE_GROUP, { fetchPolicy: "network-only" });
    const [getTemplates, { loading: loadingTemplates, data: dataTemplates, error: errorTemplates }] = useLazyQuery(GET_TEMPLATES,
        {
            fetchPolicy: "network-only"
        });
    const [updateTemplate, { loading: loadingUpdate, error: errorUpdate }] = useMutation(UPDATE_TEMPLATE,
        {
            async onCompleted() {
                await fetchTemplates();
            },
        });
    const [createTemplate, { loading: loadingCreateTemplate, error: errorCreateTemplate }] = useMutation(CREATE_TEMPLATE,
        {
            async onCompleted() {
                await fetchTemplates();
            },

        });

    const [deleteTemplate, { loading: loadingDeleteTemplate, error: errorDeleteTemplate }] = useMutation(DELETE_TEMPLATE,
        {
            async onCompleted() {
                await fetchTemplates();
            },
        }
    );


    const handleClickNew = () => {
        setEdit(false);
        setShowForm(true);
        setTemplateSelected({} as any);
    }

    const handleClickSelect = (templateParam: any) => {

        navigation.navigate("App", { template: templateParam, type: "template" });
    }

    const handleDeleteTemplate = async () => {
        await deleteTemplate({
            variables: {
                templateId: templateSelected.id
            }
        });

        setShowModalDelete(false);

    }

    const fetchTemplates = async () => {
        const { data } = await getTemplates({ variables: { groupId: dataActiveGroup?.groupActive.id } });

        // quitamos los nulos
        setTemplates(data.templetesRecent.filter((template: any) => template !== null));
    }

    const handleClickDelete = (template: any) => {
        setTemplateSelected(template);
        setShowModalDelete(true);
    }

    const handleClickEdit = (template: any) => {
        setTemplateSelected(template);
        setEdit(true);
        setShowForm(true);
    }

    useEffect(() => {

        if (dataActiveGroup) {
            setGroup(dataActiveGroup.groupActive);
            fetchTemplates();
        }

    }, [dataActiveGroup])

    useEffect(() => {
        setIsHeight(Dimensions.get('window').height > 1000);
    }, [Dimensions.get('window').height])

    return (
        <>
            <Layout
                navigation={navigation}
                title="Templates"
            >

                <View style={{ width: "80%", height: "100%" }} className="overflow-hidden flex justify-between">

                    <View style={{ width: "100%" }} className={`justify-center ${isHeight ? 'mt-12' : 'mt-5'} hidden md:block`}>
                        <Text className="text-2xl text-white " style={{ fontFamily: "Nunito" }}>Templates</Text>
                    </View>

                    <View className="">

                        <SearchTemplateInput
                            loading={loadingTemplates}
                            setSearchTemplate={setTemplates}
                            templatesInitial={templates}

                        />
                    </View>

                    <View
                        className="overflow-y-auto pt-5 h-[78%] sm:h-[78%] md:h-[73%] lg:[80%]"
                    >

                        {

                            loadingTemplates || loadingActiveGroup ? (
                                <View className=" h-[70%]">
                                    <Loader />
                                </View>
                            ) : (
                                templates.length === 0 ? (
                                    <View className="flex items-center justify-center" style={{ width: "100%" }}>
                                        <Text className="text-xl text-white" style={{ fontFamily: "Nunito" }}>No hay templates</Text>
                                    </View>

                                ) : (

                                    <ScrollView
                                        scrollEventThrottle={16}
                                    >
                                        <View
                                            className="grid gap-5 md:grid-cols-2 lg:grid-cols-3"
                                            style={{ width: "100%" }}
                                        >
                                            {
                                                templates.map((template: any) => (
                                                    <View key={template.id} className="p-4 flex flex-row text-ellipsis overflow-hidden gap-5" style={{ backgroundColor: "#D9D9D9", borderRadius: 20 }}>

                                                        <View style={{ width: "80%" }}>
                                                            <View className="flex flex-row items-center">

                                                                {template?.group && (
                                                                    <View
                                                                        className="flex items-center justify-center text-lg mr-2"
                                                                        style={{ height: "2rem", width: "2rem", backgroundColor: '#27374D', borderBottomEndRadius: 10, borderTopEndRadius: 10, borderTopStartRadius: 10 }}
                                                                    >
                                                                        <Tooltip
                                                                            title={template?.group?.name}
                                                                            leaveTouchDelay={0}
                                                                            enterTouchDelay={0}
                                                                        >
                                                                            <Text className="font-semibold text-base" style={{ color: '#D9D9D9' }}>
                                                                                {formatInitial(template?.group?.name)}
                                                                            </Text>
                                                                        </Tooltip>
                                                                    </View>
                                                                )}

                                                                <Text className="text-lg overflow-ellipsis overflow-hidden whitespace-nowrap" style={{ color: "#27374D" }}>{template.name}</Text>
                                                            </View>

                                                            <TTool
                                                                content={
                                                                    <View className="p-2 bg-white rounded-lg" style={{ maxWidth: 300 }}>
                                                                        <Text className="text-base" style={{ color: "#595959" }}>{template.instructions}</Text>
                                                                    </View>
                                                                }
                                                                placement="bottom-start"
                                                            >
                                                                <Text className="text-base mt-1 overflow-ellipsis overflow-hidden whitespace-nowrap" style={{ color: "#595959" }}>{template.instructions}</Text>
                                                            </TTool>

                                                        </View>

                                                        <View className="flex flex-col justify-around items-center" style={{ width: "15%" }}>
                                                            <View>
                                                                <TouchableRipple
                                                                    onPress={() => handleClickSelect(template)}
                                                                    style={{ marginBottom: 7 }}
                                                                    testID={`send-template-${template.id}`}
                                                                >
                                                                    <Image
                                                                        source={require('../../../assets/send.svg')}
                                                                        style={{ width: 25, height: 25 }}
                                                                        tintColor={"#526D82"}
                                                                        className="hover:transform hover:scale-110 transition duration-100 ease-in-out"
                                                                    />
                                                                </TouchableRipple>
                                                            </View>

                                                            {userState.dataUser?.id === template?.user?.id ? (
                                                                <View>
                                                                    <TouchableRipple onPress={() => handleClickEdit(template)} style={{ marginBottom: 7 }}>
                                                                        <Image
                                                                            source={require('../../../assets/edit.svg')}
                                                                            style={{ width: 25, height: 25 }}
                                                                            tintColor={"#526D82"}
                                                                            className="hover:transform hover:scale-110 transition duration-100 ease-in-out"
                                                                        />
                                                                    </TouchableRipple>
                                                                </View>
                                                            ) : (
                                                                // Placeholder for edit icon to maintain spacing
                                                                <View className="mb-2" style={{ width: 25, height: 25 }}></View>
                                                            )}

                                                            <View>
                                                                <TouchableRipple onPress={() => handleClickDelete(template)}>
                                                                    <Image
                                                                        source={require('../../../assets/delete.svg')}
                                                                        style={{ width: 25, height: 25 }}
                                                                        tintColor={"#526D82"}
                                                                        className="hover:transform hover:scale-110 transition duration-100 ease-in-out"
                                                                    />
                                                                </TouchableRipple>
                                                            </View>
                                                        </View>
                                                    </View>
                                                ))
                                            }
                                        </View>

                                    </ScrollView>

                                )
                            )
                        }

                    </View>


                    <View className="flex items-center justify-center w-full pt-5 pb-5">
                        <Button
                            mode="contained"
                            onPress={handleClickNew}
                            textColor="white"
                            buttonColor="#36AE7C"
                        >
                            Nuevo
                        </Button>
                    </View>

                </View >

            </Layout >

            <TemplateForm
                showForm={showForm}
                onDismiss={() => setShowForm(false)}
                isEdit={edit}
                template={templateSelected}
                group={group}
                updateTemplate={updateTemplate}
                createTemplate={createTemplate}
                loading={loadingUpdate || loadingCreateTemplate}
                handleClickSelect={handleClickSelect}
            />

            <AlertDialog
                showDialog={showModalDelete}
                setShowDialog={setShowModalDelete}
                OnClickAction={handleDeleteTemplate}
                text={`Eliminar este elemento es irreversible. ¿Quieres continuar?`}
                type='delete'
            />
        </>

    )
}