import { View } from 'react-native';
import { HelperText, TextInput } from 'react-native-paper';

interface InputProps {
    value: string;
    testID: string;
    onChange: any;
    existError: boolean;
    error: string | undefined;
    placeholder: string;
    label: string;
    mode?: 'outlined' | 'flat' | undefined;
    secureTextEntry?: boolean;
    onEnter?: any;
}

export default function Input({ value, onChange, existError, error, placeholder, testID, label, mode = 'outlined', secureTextEntry = false, onEnter }: InputProps) {

    return (
        <View>
            <TextInput
                mode={mode}
                label={label}
                testID={testID}
                placeholder={placeholder}
                className="mt-1 mr-3 ml-3 mb-1"
                value={value}
                onChange={onChange}
                // evento para cuando se presiona enter
                onSubmitEditing={onEnter}
                secureTextEntry={secureTextEntry}
            />

            <HelperText type="error" visible={existError} className="ml-3">
                {error}
            </HelperText>
        </View>
    )
}