import { useFormik } from "formik";
import React from "react";
import { View } from "react-native";
import { TextInput } from "react-native-paper";
import { useToast } from "react-native-toast-notifications";
import { SEARCH_TEMPLATES } from "../../modules/templates/graphql/query";
import { useLazyQuery } from "@apollo/client";


interface SearchTemplateInputProps {
    loading: boolean;
    setSearchTemplate: any;
    templatesInitial: any;
}


export default function SearchTemplateInput({ loading, setSearchTemplate, templatesInitial }: SearchTemplateInputProps) {

    const [searchTemplates, { loading: loadingSearchTemplates }] = useLazyQuery(SEARCH_TEMPLATES);


    const toast = useToast();

    const handleOnchangeSearch = (e: any) => {
        formikSearchTemplates.setFieldValue('search', e.nativeEvent.text);
    }

    const handleOnSerach = () => {
        formikSearchTemplates.handleSubmit();
    }

    const formikSearchTemplates = useFormik({
        initialValues: {
            search: "",
        },
        onSubmit: async (values) => {
            const { data: dataSearchTemplates } = await searchTemplates({
                variables: {
                    search: values.search
                }
            });

            if (dataSearchTemplates.searchTemplates.length == 0) {
                setSearchTemplate(templatesInitial);
                toast.show("No se encontró ningún template con esos datos",
                    {
                        type: "danger",
                        placement: 'top',
                        animationType: 'zoom-in',
                        style: {
                            borderRadius: 15,
                        },
                    }
                );

                return;
            }

            setSearchTemplate(dataSearchTemplates.searchTemplates);

        },
    });

    return (
        <>
            <View style={{ width: "100%" }} className="flex justify-center items-center mb-3">
                <TextInput
                    right={
                        <TextInput.Icon
                            icon="send"
                            onPress={handleOnSerach}
                            disabled={loading || loadingSearchTemplates}
                        />
                    }

                    mode='flat'
                    className='rounded-full'
                    testID='input-search'
                    placeholder='Buscar template ...'
                    style={{ width: "100%", backgroundColor: '#27374D', paddingLeft: 15 }}
                    textColor='#FFF'
                    placeholderTextColor='#FFF'
                    underlineColor='#526D82'
                    activeUnderlineColor='#526D82'
                    disabled={loading || loadingSearchTemplates}
                    value={formikSearchTemplates.values.search}
                    onChange={handleOnchangeSearch}
                    onKeyPress={(e: any) => {
                        if (e.nativeEvent.key === "Enter" && !e.nativeEvent.shiftKey)
                            handleOnSerach();
                    }
                    }

                />
            </View>

        </>

    )
}