import { Text, View, Image, TouchableOpacity, Clipboard } from 'react-native';
import { useToast } from "react-native-toast-notifications";

interface HeaderCodeProps {
    lenguage: string;
    code: string;
}

export default function HeaderCode({ lenguage, code }: HeaderCodeProps) {

    const toast = useToast();

    const copyToClipboard = () => {

        //Copiar al portapapeles
        Clipboard.setString(code);

        toast.show("Texto copiado en el portapapeles",
            {
                type: "success",
                placement: 'top',
                animationType: 'zoom-in',
                style: {
                    borderRadius: 15,
                },
            })
    };


    return (
        <View
            style={{
                backgroundColor: 'white',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: 10,
                marginBottom: -20,
                borderTopLeftRadius: '16px',
                borderTopRightRadius: '16px',
            }}
        >
            <Text style={{ fontSize: 18, fontWeight: 'bold' }}>{lenguage}</Text>
            <TouchableOpacity
                onPress={copyToClipboard}
            >
                <Image
                    source={require('../../../../assets/copy.png')}
                    style={{ width: 20, height: 20 }}
                />
            </TouchableOpacity>
        </View>
    );
}