import { useEffect, useState } from "react";
import { Image, View, Text } from 'react-native';

import { TouchableRipple } from 'react-native-paper';
import React from "react";
import { DropdownComponent } from "../../../components/forms/DropdownComponent";
import { useQuery } from "@apollo/client";
import { GET_TEMPLATES } from "../../templates/graphql/query";
import { GROUP_API_KEYS } from "../../profile/graphql/query";

interface IHeadersMovile {
    setShowHeaders: any;
    onHandleSelectTemplate: any;
    setPlaceholderTemplateApp: any;
    placeholderTemplateApp: any;
}


export default function HeadersMovile({ setShowHeaders, onHandleSelectTemplate, setPlaceholderTemplateApp, placeholderTemplateApp }: IHeadersMovile) {

    const [templates, setTemplates] = useState([]);
    const [models, setModels] = useState([]);
    const [placeholderModel, setPlaceholderModel] = useState("Selecciona un modelo");

    const { data: dataTemplates, loading: loadingTemplates, error: errorTemplates } = useQuery(GET_TEMPLATES);
    const { data: dataApiKeys, loading: loadingApiKeys, error: errorApiKeys } = useQuery(GROUP_API_KEYS);


    const handleSelectTemplate = (item: any) => {

        setShowHeaders(false);
        onHandleSelectTemplate(item.data.instructions, item.data.output, item.value, item.label);
        setPlaceholderTemplateApp(item.label);

    }

    const handleSelectModel = async (item: any) => {
        setPlaceholderModel(item.label);
        setShowHeaders(false);
    }

    useEffect(() => {

        if (dataTemplates) {
            setTemplates(dataTemplates.templetesRecent.map((item: any) => {
                return {
                    label: item.name,
                    value: item.id,
                }
            }));
        }

    }, [dataTemplates]);

    useEffect(() => {

        if (dataApiKeys) {
            setModels(dataApiKeys.groupApiKeys.map((item: any) => {
                return {
                    label: item.name,
                    value: item.id,
                }
            }));
        }

    }, [dataApiKeys]);



    return (
        <View className={`h-screen w-screen items-center justify-center absolute top-0 left-0 z-50 bg-[#27374D]`}>

            <View className="flex flex-row-reverse">
                <TouchableRipple
                    onPress={() => { setShowHeaders(false) }}
                >
                    <Image
                        source={require('../../../../assets/close.svg')}
                        style={{ width: 25, height: 25 }}
                        className="hover:opacity-50 cursor-pointer mr-4 mt-4"
                    />
                </TouchableRipple>
            </View>

            <View className="flex flex-col">

                <View className="flex flex-col items-center justify-center">
                    <Text className="text-white text-base font-semibold mr-3">Template </Text>
                    {
                        loadingTemplates ? (
                            <View className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white" />

                        ) : (
                            <View className=" w-56">

                                <DropdownComponent
                                    data={templates}
                                    onChange={handleSelectTemplate}
                                    placeholder={placeholderTemplateApp}

                                />

                            </View>
                        )
                    }

                </View>

                <View className="flex flex-col items-center justify-center">
                    <Text className="text-white text-base font-semibold mr-3">Modelo </Text>

                    {
                        loadingApiKeys ? (
                            <View className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white" />

                        ) : (
                            <View className=" w-56">

                                <DropdownComponent
                                    data={models}
                                    onChange={handleSelectModel}
                                    placeholder={placeholderModel}
                                />

                            </View>
                        )
                    }

                </View>
            </View>


        </View>
    )
}