import { QUERY_USER } from "./graphql/query";
import { ACCEPT_INVITATION, COMPLETE_USER } from "./graphql/mutation";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";

import ValidateNumber from "./screens/ValidateNumber";
import App from "./screens/App";
import LoaderScreen from "../../components/ui/LoaderScreen";
import { useContext, useEffect, useState, } from "react";
import { useToast } from "react-native-toast-notifications";
import React from "react";
import { captureException, captureMessage } from "@sentry/react";
import UserContext from "../../../context/user/UserContext";


export default function Main({ route, navigation }: any) {

    const { logout, userState, dispatch } = useContext(UserContext);

    const [validateEmail, setValidateEmail] = useState(true);

    const [getUser, { loading: loadingUser, error: errorUser }] = useLazyQuery(QUERY_USER);
    const [completeUser, { loading: completeLoading, error: completeError }] = useMutation(COMPLETE_USER);
    const [acceptInvitation, { loading: acceptLoading, error: acceptError }] = useMutation(ACCEPT_INVITATION);

    const { params } = route;

    const toast = useToast();

    const handleCompleteUser = async (values: any) => {
        try {
            await completeUser({
                variables: {
                    name: values.name,
                    lastName: values.lastName,
                },
                onCompleted: async (response: any) => {
                    const { completeUser } = response;
                    setValidateEmail(completeUser.user.validationEmail);

                    await getUserFunction(true);
                }
            });

        } catch (error) {
            console.log(error);
        }
    }

    const handleAcceptInvitation = async (invitationId: string, status: string) => {
        const { data } = await acceptInvitation({
            variables: {
                invitationId,
                status
            }
        });

        toast.show(data.acceptInvitation.message, {
            type: data.acceptInvitation.success ? "success" : "danger",
            placement: "top",
            animationType: "zoom-in",
            style: {
                borderRadius: 15,
            },
        });

    }

    const getUserFunction = async (isComplete = false) => {
        const { data, error } = await getUser();

        if (error) {
            captureException(error);
            await logout();
            navigation.navigate("Home");
        }

        const subscription = data.myUser.groups.some((group: any) => group.subscription.plan.daysHistorial === -1);

        dispatch({ type: "SET_USER", payload: {
            ...userState,
            dataUser: data.myUser,
            subscription
        } });

        if (!isComplete)
            setValidateEmail(data.myUser.validationEmail);

    }

    useEffect(() => {

        if (params?.type === "complete") {
            handleCompleteUser(params.data);
            getUserFunction();
        }

        else if (params?.type === "invitation") {
            const { invitationId, status } = params.data;
            handleAcceptInvitation(invitationId, status);
            getUserFunction();
        }

        else if (params?.type === "refresh") {
            getUserFunction();
        }

        const date = new Date();

    }, [params]);


    if (completeLoading || loadingUser) {
        return <LoaderScreen />
    };

    if (completeError || errorUser) {
        console.log(completeError);
        console.log(errorUser);
    }


    return (
        <>
            <App
                navigation={navigation}
                route={route}
                validateEmail={validateEmail}
            />
        </>
    )
}