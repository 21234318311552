import { PaperProvider } from 'react-native-paper';
import Main from './src/router/Main'
import UserProvider from "./context/user/UserProvider";
import ApolloProvider from "./context/apollo/ApolloProvider";
import { ToastProvider } from 'react-native-toast-notifications';
import { Image } from "react-native";

import "./tailwind.css";
import * as Sentry from "@sentry/react";
import { config } from './config';
import React from 'react';


if (config.ENV !== 'dev') {
  Sentry.init({
    dsn: "https://787f0539f164dc413f9409838ddfb070@o4506556327985152.ingest.sentry.io/4506623375114240",
    environment: config.ENV,
  });
}

export default function App() {
  return (
    <>
      <UserProvider>
        <ApolloProvider>
          <PaperProvider>
            <ToastProvider
              style={{ borderRadius: 15 }}
              textStyle={{ color: '#000000', fontSize: 15, fontWeight: '400' }}
              placement="top"
              animationType="zoom-in"
              duration={2500}

              successIcon={<Image source={require('./assets/success.svg')} style={{ width: 18, height: 18, marginRight: 15 }} tintColor={'#004225'} />}
              successColor="rgba(54, 174, 124, 0.90)"

              dangerColor="rgba(252, 174, 174, 0.90);"
              dangerIcon={<Image source={require('./assets/error.svg')} style={{ width: 18, height: 18, marginRight: 15 }} tintColor={'#FF6666'} />}

            >
              <Main />
            </ToastProvider>
          </PaperProvider>
        </ApolloProvider>
      </UserProvider>
    </>

  );
}
