import { Image } from 'react-native';
import { Tooltip, TouchableRipple } from 'react-native-paper';
import { Button } from 'react-native-paper';


interface ItemMenuProps {
    title: string;
    source: any;
    onPress?: () => void;
    color?: string;
}

export default function ItemMenu({ title, source, onPress, color, testID }: ItemMenuProps) {

    return (
        <Button
            mode="text"
            onPress={onPress}
            className="mb-3 hover:transform hover:scale-110 transition duration-100 ease-in-out"
            testID={testID}
        >
            <Tooltip title={title} leaveTouchDelay={10} enterTouchDelay={10}>
                <TouchableRipple
                    onPress={onPress}
                >
                    <Image
                        source={source}
                        style={{ width: 30, height: 30 }}
                        tintColor={color}
                    />
                </TouchableRipple>
            </Tooltip>
        </Button>
    )

}