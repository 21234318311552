import { SET_USER } from "./types";

interface Action {
    type: string;
    payload: any;
}

export default (state: any, action: Action) => {

    const { payload, type } = action;

    switch (type) {

        case SET_USER:

            if(!payload) return {
                ...state,
                email: null,
                emailVerified: null,
                token: null,
                expirationTime: null,
                uid: null,
                user: null,
                dataUser: null,
                subscription: false
            };

            return {
                ...state,
                email: payload.email,
                emailVerified: payload.emailVerified,
                token: payload?.stsTokenManager?.accessToken ||  payload?.user?.stsTokenManager?.accessToken,
                expirationTime: payload?.stsTokenManager?.expirationTime || payload?.user?.stsTokenManager?.expirationTime,
                uid: payload.uid,
                user: payload,
                dataUser: payload.dataUser,
                subscription: payload.subscription
            };

        default:
            return state;
    }
};