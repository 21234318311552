import { gql } from "@apollo/client";


export const METRICS_GENERAL = gql`
    query metricsToken($dateIni: Date!, $dateEnd: Date!){
        metricsToken(dateIni: $dateIni, dateEnd: $dateEnd) {
            createdAt
            totalTokens
            totalPrice
            provider
        }
    }
`;