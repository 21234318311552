

export const formatInitial = (word: string) => {

    // validamos que tenga dos palabras
    if (word.split(" ").length === 2) {
        // separamos las palabras
        const words = word.split(" ");
        // retornamos la primera letra de cada palabra en mayuscula
        return `${words[0].charAt(0).toUpperCase()}${words[1].charAt(0).toUpperCase()}`;
    } else {
        // retornamos la primera letra de la palabra en mayuscula
        return `${word.charAt(0).toUpperCase()}${word.charAt(3).toUpperCase()}`;
    }

}