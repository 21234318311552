import { ReactNode, useEffect, useState } from "react";
import { Image, View, Text } from 'react-native';
import Menu from "./components/Menu";
import HistoryChat from "./components/HistoryChat";
import { TouchableRipple } from 'react-native-paper';
import React from "react";
import HeadersMovile from "./components/HeadersMovile";

interface LayaoutProps {
    children?: ReactNode;
    navigation?: any;
    onHandleSelectTemplate?: any;
    template?: any;
    placeholderTemplateApp?: any;
    setPlaceholderTemplateApp?: any;
    resetData?: any;
    title?: string;
    menuRight?: boolean;
}

export default function LayoutMain({ children, navigation, onHandleSelectTemplate, template, placeholderTemplateApp, setPlaceholderTemplateApp, resetData, title, menuRight = false }: LayaoutProps) {

    const [recent, setRecent] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const [showHeaders, setShowHeaders] = useState(false);

    const handleClickMenu = () => {
        setShowMenu(!showMenu);
    }

    const handleClickHeaders = () => {
        setShowHeaders(true);
    }

    useEffect(() => {


        if (template?.name) {
            setPlaceholderTemplateApp(template.name);
        } else {

            if (setPlaceholderTemplateApp)
                setPlaceholderTemplateApp("Selecciona un template");
        }

    }, [template, placeholderTemplateApp])


    return (

        <>
            <View className="h-screen w-screen flex flex-col md:flex-row" style={{ backgroundColor: "#526D82" }}>

                <View className="flex md:hidden flex-row justify-between items-center w-[100%] pt-4 h-[5%]">

                    <View className="md:hidden pl-2" style={{ width: 'auto' }}>
                        <TouchableRipple
                            onPress={handleClickMenu}
                        >
                            <Image
                                source={require('../../../assets/menu-rigth.svg')}
                                style={{ width: 30, height: 30 }}
                                tintColor="#fff"
                            />
                        </TouchableRipple>
                    </View>



                    {
                        title && (
                            <View className="justify-center items-center md:items-start">
                                <Text className="text-2xl text-white " style={{ fontFamily: "Nunito" }}>{title}</Text>
                            </View>
                        )
                    }

                    

                    <View className="md:hidden mr-2" style={{ width: 30, height: 30 }}>
                        {
                            menuRight && (
                                <TouchableRipple
                                    onPress={handleClickHeaders}
                                >
                                    <Image
                                        source={require('../../../assets/menu-left.svg')}
                                        style={{ width: 30, height: 30 }}
                                        tintColor="#fff"
                                    />
                                </TouchableRipple>
                            )
                        }
                        
                    </View>


                </View>

                <Menu
                    navigation={navigation}
                    showRecent={() => setRecent(!recent)}
                    showMenu={showMenu}
                    setShowMenu={setShowMenu}
                    resetData={resetData}
                />

                <View className="items-center flex md:w-[95%] md:h-[100%] w-[100%] h-[95%]">
                    {children}
                </View>
            </View>



            {
                recent && <HistoryChat navigation={navigation} showRecent={() => setRecent(!recent)} />
            }


            {
                showHeaders && (
                    <HeadersMovile
                        onHandleSelectTemplate={onHandleSelectTemplate}
                        placeholderTemplateApp={placeholderTemplateApp}
                        setPlaceholderTemplateApp={setPlaceholderTemplateApp}
                        setShowHeaders={setShowHeaders}

                    />
                )
            }

        </>

    )
}