import React, { useEffect, useReducer, useState } from "react";
import {
    onAuthStateChanged,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut,
    sendSignInLinkToEmail,
    isSignInWithEmailLink,
    signInWithEmailLink,
    confirmPasswordReset,
    sendPasswordResetEmail
} from 'firebase/auth'
import { auth } from '../../firabaseConfig';

import UserContext from "./UserContext";
import UserReducer from "./UserReducer";

import { SET_USER } from "./types";
import { config } from "../../config";

const UserProvider = (props: any) => {

    const initialState = {
        email: '',
        emailVerified: null,
        token: null,
        expirationTime: null,
        uid: null,
        user: null,
        dataUser: null,
        subscription: false
    };

    const [state, dispatch] = useReducer(UserReducer, initialState);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {

            if (user) {
                dispatch({ type: SET_USER, payload: user });
            } else {
                logout();
            }
        })

        return unsubscribe;
    }, [])

    const login = async (email: string, password: string) => {
        const response = await signInWithEmailAndPassword(auth, email, password);
        dispatch({ type: SET_USER, payload: response.user });
    }

    const logout = async () => {
        await signOut(auth);
        dispatch({ type: SET_USER, payload: null });
    }

    const signup = async (email: string, password: string) => {
        await createUserWithEmailAndPassword(auth, email, password);

        // enviamos el correo de verificacion
        await sendSignInLinkToEmail(auth, email, {
            url: config.APP_URL,
            handleCodeInApp: true,
        })
    }

    const signInWithLink = async (link: string) => {
        const valid = isSignInWithEmailLink(auth, link);

        if (valid) {
            const email = window.localStorage.getItem('efs') || '';

            const response = await signInWithEmailLink(auth, email, link);
            dispatch({ type: SET_USER, payload: response.user });
        }
    }

    const reSendEmail = async () => {
        // volvemos a enviar el correo de verificacion
        const email = state.email;

        await sendSignInLinkToEmail(auth, email, {
            url: config.APP_URL,
            handleCodeInApp: true,
        })
    }

    const recoveryPassword = async (email: string) => {
        await sendPasswordResetEmail(auth, email, {
            url: config.APP_URL,
            handleCodeInApp: true,
        });
    }

    return (
        <UserContext.Provider
            value={{
                userState: state,
                login,
                logout,
                signup,
                signInWithLink,
                reSendEmail,
                loading,
                setLoading,
                recoveryPassword,
                dispatch
            }}
        >
            {props.children}
        </UserContext.Provider>
    );
};

export default UserProvider;