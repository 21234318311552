import React, { useEffect, useState, memo, useCallback, useRef } from "react";
import { ScrollView, View } from "react-native";
import Icon from './Icon';
import MessageChat from "./MessageChat";
import { Divider, TouchableRipple } from "react-native-paper";
import { useMutation } from "@apollo/client";
import { SELECT_SUB_MESSAGE } from "../graphql/mutation";

interface IChatComponent {
    messageChat: any;
    setMessages: any;
    messages: any;
    chatRef: any;
}

const ChatComponent = ({ messageChat, messages, setMessages, chatRef }: IChatComponent) => {
    const [autoScrolling, setAutoScrolling] = useState(true);
    const autoScrollTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    const [selectSubMessage, { loading: loadingSelectSubMessage }] = useMutation(SELECT_SUB_MESSAGE);
    const handleScroll = useCallback((event: any) => {
        const { contentOffset, contentSize, layoutMeasurement } = event.nativeEvent;
        const scrollPosition = contentOffset.y + layoutMeasurement.height;
        const totalHeight = contentSize.height;
        const isCloseToBottom = totalHeight - scrollPosition < 100;
        setAutoScrolling(isCloseToBottom);
    }, []);

    const handleSelectMessage = useCallback(async (message: any) => {
        await selectSubMessage({
            variables: {
                subMessageId: message.id
            }
        });
        messageChat.forEach((messageChat: any) => {
            messageChat.subMessages.forEach((subMessage: any) => {
                subMessage.selected = (subMessage.messageId === message.messageId && subMessage.id === message.id);
            });
        });
        setMessages([...messageChat]);
    }, [messageChat, selectSubMessage, setMessages]);

    useEffect(() => {
        if (autoScrolling && chatRef.current) {
            const timeoutId = setTimeout(() => {
                chatRef.current.scrollToEnd({ animated: true });
            }, 0);
            return () => clearTimeout(timeoutId);
        }
    }, [messages, autoScrolling]);

    useEffect(() => {
        if (autoScrolling && chatRef.current) {
            requestAnimationFrame(() => {
                chatRef.current.scrollToEnd({ animated: true });
            });
        }
    }, [messages]);


    useEffect(() => {
        return () => {
            if (autoScrollTimeoutRef.current) {
                clearTimeout(autoScrollTimeoutRef.current);
            }
        };
    }, []);


    return (
        <ScrollView
            style={{ width: '100%' }}
            className="grid grid-cols-1 gap-5"
            ref={chatRef}
            onScroll={handleScroll}
            scrollEventThrottle={16}
        >
            <View className='items-center justify-center' testID={'messages-list'}>
                {messages.map((message: any, index: any) => (
                    <View key={`container-message-${index}`} style={{ width: '100%' }} className="flex flex-col justify-center items-center ">
                        <View className={`text-white p-3 grid grid-cols-${message.subMessages.length} gap-10`} style={{ width: "90%", height: "100%" }}>
                            {message.subMessages.map((subMessage: any, index: number) => (
                                message.role === "assistant" && message.subMessages.length > 1 ? (
                                    <TouchableRipple key={`touchable-${index}`} onPress={() => handleSelectMessage(subMessage)} className={`${subMessage.selected ? 'bg-[#537fa3d0]' : ''} w-full hover:bg-[#477296] hover:cursor-pointer`}>
                                        <View className="flex flex-row">
                                            <View className="flex justify-center items-center md:justify-start">
                                                <Icon type={message.role} provider={subMessage?.provider} />
                                            </View>
                                            <View style={{ width: '90%' }} className="md:pl-3">
                                                <MessageChat message={subMessage.content} />
                                            </View>
                                        </View>
                                    </TouchableRipple>
                                ) : (
                                    <View key={`message-${index}`} className="flex flex-row w-full">
                                        <View className="flex justify-center items-center md:justify-start">
                                            <Icon type={message.role} provider={subMessage?.provider} />
                                        </View>
                                        <View style={{ width: '90%' }} className="md:pl-3">
                                            <MessageChat message={subMessage.content} />
                                        </View>
                                    </View>
                                )
                            ))}
                        </View>
                        <View className="bg-slate-100" style={{ width: "100%" }}>
                            <Divider className="text-white" style={{ width: "100%" }} />
                        </View>
                    </View>
                ))}
            </View>
        </ScrollView>
    );
};

export default memo(ChatComponent);