import { gql } from "@apollo/client";

export const CREATE_GROUP = gql`
    mutation createGroup($name: String!, $description: String!) {
        createGroup(description: $description, name: $name) {
            message
            success
        }
    }
`;

export const DELETE_GROUP = gql`
    mutation deleteGroup($groupId: ID!) {
        deleteGroup(groupId: $groupId) {
            message
            success
        }
    }
`;

export const EDIT_GROUP = gql`
    mutation editGroup($groupId: ID!, $name: String!) {
        editGroup(groupId: $groupId, name: $name, description: "") {
            message
            success
        }
    }
`;

export const ACTIVE_GROUP = gql`
    mutation activeGroup($groupId: ID!) {
        activeGroup(groupId: $groupId){
            message
            success
        }
    }
`;

export const CREATE_API_KEY = gql`
    mutation createApiKey($name: String!, $model: String!, $key: String!){
        createApiKey(
            name: $name,
            model: $model,
            key: $key
        ){
            message
            success
        }
    }
`;

export const ACTIVE_API_KEY = gql`
    mutation activeApiKey($apiKeyId: ID!) {
        activeApiKey(apiKeyId: $apiKeyId){
            message
            success
        }
    }
`;

export const UPDATE_API_KEY = gql`
    mutation updateApiKey($apiKeyId: ID!, $key: String!, $modelIaId: ID!, $name: String!) {
        updateApiKey(
            apiKeyId: $apiKeyId
            key: $key
            modelIaId: $modelIaId
            name: $name
        ) {
            message
            success
        }
    }
`;

export const CREATE_INVITATION = gql`
    mutation createInvitation($name: String!, $email: String!, $groupId: ID!, $roleId: ID!){
        createInvitation(
            name: $name,
            email: $email,
            groupId: $groupId,
            roleId: $roleId
        ){
            message
            success
        }
    }
`;

export const RESEND_INVITATION = gql`
    mutation resendInvitation($invitationId: ID!){
        resendInvitation(
            invitationId: $invitationId
        ){
            message
            success
        }
    }
`;

export const DELETE_API_KEY = gql`
    mutation deleteApiKey($apiKeyId: ID!) {
        deleteApiKey(apiKeyId: $apiKeyId){
            message
            success
        }
    }
`;

export const UPDATE_INVITATION = gql`
    mutation updateInvitation($invitationId: ID!, $email: String!, $name: String!, $roleId: ID!) {
        updateInvitation(invitationId: $invitationId, email: $email, name: $name, roleId: $roleId){
            message
            success
        }
    }
`;

export const REMOVE_USER_FROM_GROUP = gql`
    mutation removeUserFromGroup($userId: ID!) {
        removeUserFromGroup(userId: $userId){
            message
            success
        }
    }
`;

export const DELETE_INVITATION = gql`
    mutation deleteInvitation($invitationId: ID!) {
        deleteInvitation(invitationId: $invitationId){
            message
            success
        }
    }
`;

export const UPDATE_USER_GROUP = gql`
    mutation updateUserGroup($userId: ID!, $roleId: ID!) {
        updateUserGroup(userId: $userId, roleId: $roleId){
            message
            success
        }
    }
`;