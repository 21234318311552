import React, { useEffect } from "react";
import { useState } from "react";
import { View, Text, ScrollView, StyleSheet, TouchableOpacity } from "react-native";
import LayoutMain from "../layout/Main";
import Modal from "../../components/ui/Modal";
import { Calendar, DateData } from 'react-native-calendars';
import { Button } from "react-native-paper";
import { useLazyQuery, useQuery } from "@apollo/client";
import { METRICS_GENERAL } from "./graphql/query";
import LoaderScreen from "../../components/ui/LoaderScreen";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

interface SelectedDates {
    start?: string;
    end?: string;
}

const fechaHoy = new Date();


export default function MainMetrics({ navigation }: any) {

    const { data: dataMetricsGeneral, loading: loadingMetricsGeneral, error: errorMetricsGeneral } = useQuery(METRICS_GENERAL, {
        variables: {
            dateIni: new Date().toISOString().slice(0, 10),
            dateEnd: new Date().toISOString().slice(0, 10)
        }
    });
    const [getMetrics, { data: dataMetricsLazy, loading: loadingMetricsLazy, error: errorMetricsLazy }] = useLazyQuery(METRICS_GENERAL);

    const [visible, setVisible] = useState(false);
    const [placeholder, setPlaceholder] = useState(fechaHoy.toISOString().split('T')[0]);
    const [dataGraph, setDataGraph] = useState({
        labels: [],
        datasets: []

    });

    const [selectedDates, setSelectedDates] = useState<SelectedDates>({});

    const onDayPress = (day: DateData) => {
        if (!selectedDates.start || (selectedDates.start && selectedDates.end)) {
            setSelectedDates({ start: day.dateString, end: undefined });
        } else if (selectedDates.start && !selectedDates.end) {
            if (day.dateString < selectedDates.start) {
                setSelectedDates({ start: day.dateString });
            } else {
                setSelectedDates({ ...selectedDates, end: day.dateString });
            }
        }

    };

    const selectDates = async () => {
        setVisible(false);
        console.log(selectedDates);

        const { data: dataMet } = await getMetrics({
            variables: {
                dateIni: selectedDates.start,
                dateEnd: selectedDates.end
            }
        });

        console.log(dataMet);

        // openIA
        const dataOpenAI = dataMet.metricsToken.filter((item: any) => item.provider === 'OpenAI');
        const labelsOpenAI = dataOpenAI.map((item: any) => item.createdAt);
        const dataOpenAIValues = dataOpenAI.map((item: any) => item.totalPrice);

        // Google
        const dataGoogle = dataMet.metricsToken.filter((item: any) => item.provider === 'Google');
        const labelsGoogle = dataGoogle.map((item: any) => item.createdAt);
        const dataGoogleValues = dataGoogle.map((item: any) => item.totalPrice);

        // Microsoft
        const dataMicrosoft = dataMet.metricsToken.filter((item: any) => item.provider === 'Anthropic');
        const labelsMicrosoft = dataMicrosoft.map((item: any) => item.createdAt);
        const dataMicrosoftValues = dataMicrosoft.map((item: any) => item.totalPrice);

        // unimos los labels para que no se repitan
        const labels = [...new Set([...labelsOpenAI, ...labelsGoogle, ...labelsMicrosoft])];

        const data: any = {
            labels: labels,
            datasets: [
                {
                    label: 'OpenAI',
                    data: labels.map((label: any) => {
                        const index = labelsOpenAI.indexOf(label);
                        return index !== -1 ? dataOpenAIValues[index] : 0;
                    }),
                    borderColor: 'red',
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                },
                {
                    label: 'Google',
                    data: labels.map((label: any) => {
                        const index = labelsGoogle.indexOf(label);
                        return index !== -1 ? dataGoogleValues[index] : 0;
                    }),
                    borderColor: 'blue',
                    backgroundColor: 'rgba(54, 162, 235, 0.5)',
                },
                {
                    label: 'Anthropic',
                    data: labels.map((label: any) => {
                        const index = labelsMicrosoft.indexOf(label);
                        return index !== -1 ? dataMicrosoftValues[index] : 0;
                    }),
                    borderColor: 'green',
                    backgroundColor: 'rgba(75, 192, 192, 0.5)',
                },
            ],
        };

        setDataGraph(data);


    }

    const markDates = () => {
        let marked: { [date: string]: any } = {};
        let startDate = selectedDates.start ? new Date(selectedDates.start) : undefined;
        let endDate = selectedDates.end ? new Date(selectedDates.end) : undefined;

        if (startDate && endDate) {
            for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
                let key = d.toISOString().split('T')[0];
                if (startDate.toISOString().split('T')[0] === key) {
                    marked[key] = { ...marked[key], startingDay: true, color: '#70d7c7', textColor: 'white' };
                } else if (endDate.toISOString().split('T')[0] === key) {
                    marked[key] = { ...marked[key], endingDay: true, color: '#70d7c7', textColor: 'white' };
                } else {
                    marked[key] = { color: '#70d7c7', textColor: 'white' };
                }
            }
        } else if (startDate) {
            marked[startDate.toISOString().split('T')[0]] = { startingDay: true, endingDay: true, color: '#70d7c7', textColor: 'white' };
        }

        return marked;
    };

    useEffect(() => {

        if (dataMetricsGeneral) {

            // openIA
            const dataOpenAI = dataMetricsGeneral.metricsToken.filter((item: any) => item.provider === 'OpenAI');
            const labelsOpenAI = dataOpenAI.map((item: any) => item.createdAt);
            const dataOpenAIValues = dataOpenAI.map((item: any) => item.totalPrice);

            // Google
            const dataGoogle = dataMetricsGeneral.metricsToken.filter((item: any) => item.provider === 'Google');
            const labelsGoogle = dataGoogle.map((item: any) => item.createdAt);
            const dataGoogleValues = dataGoogle.map((item: any) => item.totalPrice);

            // Microsoft
            const dataMicrosoft = dataMetricsGeneral.metricsToken.filter((item: any) => item.provider === 'Anthropic');
            const labelsMicrosoft = dataMicrosoft.map((item: any) => item.createdAt);
            const dataMicrosoftValues = dataMicrosoft.map((item: any) => item.totalPrice);

            // unimos los labels para que no se repitan
            const labels = [...new Set([...labelsOpenAI, ...labelsGoogle, ...labelsMicrosoft])];

            const data: any = {
                labels: labels,
                datasets: [
                    {
                        label: 'OpenAI',
                        data: labels.map((label: any) => {
                            const index = labelsOpenAI.indexOf(label);
                            return index !== -1 ? dataOpenAIValues[index] : 0;
                        }),
                        borderColor: 'red',
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    },
                    {
                        label: 'Google',
                        data: labels.map((label: any) => {
                            const index = labelsGoogle.indexOf(label);
                            return index !== -1 ? dataGoogleValues[index] : 0;
                        }),
                        borderColor: 'blue',
                        backgroundColor: 'rgba(54, 162, 235, 0.5)',
                    },
                    {
                        label: 'Anthropic',
                        data: labels.map((label: any) => {
                            const index = labelsMicrosoft.indexOf(label);
                            return index !== -1 ? dataMicrosoftValues[index] : 0;
                        }),
                        borderColor: 'green',
                        backgroundColor: 'rgba(75, 192, 192, 0.5)',
                    },
                ],
            };

            setDataGraph(data);

        }
    }, [dataMetricsGeneral]);

    if (loadingMetricsGeneral) return <LoaderScreen />


    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            }
        },
    };


    return (
        <LayoutMain navigation={navigation}>
            <ScrollView
                style={{
                    width: "100%",
                    height: "100%",
                }}
            >

                <View style={{ width: "100%", height: "98%", paddingLeft: '10%', paddingRight: '10%' }} >

                    <View style={{ width: "100%" }} >
                        <Text
                            className="text-2xl text-white mt-5"
                            style={{ fontFamily: "Nunito" }}
                        >
                            Métricas
                        </Text>
                    </View>

                    <View style={{ width: "100%" }} >


                        {/* Texto que al dar click muestra un modal */}
                        <View className="mt-5 mb-5 flex flex-row items-center">

                            <Text className="text-white text-base">
                                Seleccione un rango de fechas:
                            </Text>

                            <TouchableOpacity
                                onPress={() => {
                                    setVisible(true);
                                }}
                            >
                                <Text style={{ color: 'blue' }} className="text-base ml-5">
                                    {
                                        // Si hay fecha de inicio y fin, mostrarlas
                                        selectedDates.start && selectedDates.end
                                            ? `${selectedDates.start} - ${selectedDates.end}`
                                            // Si solo hay fecha de inicio, mostrarla
                                            : selectedDates.start
                                                ? selectedDates.start
                                                // Si no hay fecha de inicio, mostrar un placeholder
                                                : placeholder
                                    }
                                </Text>
                            </TouchableOpacity>
                        </View>


                        {
                            dataMetricsGeneral && (

                                <View className="bg-black">
                                    <Line
                                        options={options}
                                        data={dataGraph}
                                    />
                                </View>
                            )
                        }


                    </View>

                </View>
            </ScrollView>

            <Modal
                visible={visible}
                onDismiss={() => {
                    setVisible(false);
                }}
                title="Seleccionar fechas"
            >
                <View>
                    <Calendar

                        onDayPress={onDayPress}
                        markedDates={markDates()}
                        markingType="period"
                        renderArrow={(direction) => <Text>{direction === 'left' ? '<' : '>'}</Text>}
                    />
                    <Button onPress={selectDates}>Aceptar</Button>

                </View>
            </Modal>

        </LayoutMain>
    )
}