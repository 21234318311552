import { useContext, useEffect, useState } from 'react';
import { Text, View } from 'react-native';
import { Divider } from 'react-native-paper';
import { Button, TouchableRipple } from "react-native-paper";
import { Image } from "react-native";
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { GET_ACTIVE_GROUP, GET_TEMPLATES, GET_TEMPLATES_BY_GROUP } from '../../templates/graphql/query';
import { API_KEYS_BY_GROUP, API_KEY_ACTIVE, GET_INVITATIONS_AND_USERS_BY_GROUP, GROUPS_BY_USER, GROUP_API_KEYS } from '../../profile/graphql/query';
import { DropdownComponent } from '../../../components/forms/DropdownComponent';
import { ChecksDropdownComponent } from '../../../components/forms/ChecksDropdownComponent';
import React from 'react';
import { ACTIVE_API_KEY, ACTIVE_GROUP } from '../../profile/graphql/mutation';
import UserContext from '../../../../context/user/UserContext';

interface HeaderAppProps {
    onHandleResetChat: () => void;
    onHandleSelectTemplate: any;
    template?: any;
    setPlaceholderTemplateApp?: any;
    placeholderTemplateApp?: any;
    setGeneralPrompt?: any;
    setModelsSelected?: any;
    defaultModels?: any;
}

export default function HeaderApp({ onHandleResetChat, onHandleSelectTemplate, template, setPlaceholderTemplateApp, placeholderTemplateApp, setGeneralPrompt, setModelsSelected, defaultModels }: HeaderAppProps) {


    const { userState } = useContext(UserContext);

    const { data: dataTemplates, loading: loadingTemplates, error: errorTemplates } = useQuery(GET_TEMPLATES);
    const [getModels, { data: dataModels, loading: loadingModels, error: errorModels }] = useLazyQuery(API_KEYS_BY_GROUP);

    const [setActiveGroup, { data: dataActiveGroup, loading: loadingActiveGroup, error: errorActiveGroup }] = useMutation(ACTIVE_GROUP,
        {
            refetchQueries: [
                {
                    query: API_KEY_ACTIVE,
                },
                {
                    query: GET_ACTIVE_GROUP,
                },
                {
                    query: GROUP_API_KEYS,
                },
                {
                    query: GET_INVITATIONS_AND_USERS_BY_GROUP,
                },
                {
                    query: GET_TEMPLATES_BY_GROUP,
                }
            ],
        });

    const [activeApiKey, { data: dataActiveApiKey, loading: loadingActiveApiKey, error: errorActiveApiKey }] = useMutation(ACTIVE_API_KEY,
        {
            refetchQueries: [
                {
                    query: API_KEY_ACTIVE,
                },
            ],
        });


    const [groups, setGroups] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [models, setModels] = useState([] as any);

    const [groupSelected, setGroupSelected] = useState('');
    const [templateSelected, setTemplateSelected] = useState({} as any);
    const [selectedModels, setSelectedModels] = useState([] as any);

    // const [placeholderTemplate, setPlaceholderTemplate] = useState("Selecciona un template");
    const [placeholderModel, setPlaceholderModel] = useState("Selecciona un modelo");

    const handleSelectGroup = async (item: any) => {

        // hacemos su grupo activo
        onHandleResetChat();
        setActiveGroup({ variables: { groupId: item.value } });
        setGroupSelected(item.label);


        const { data: dataModels } = await getModels({ variables: { groupId: item.value } });

        setModels(dataModels.apiKeysByGroup.map((item: any) => {
            return {
                label: item.name,
                value: item.id
            }
        }))
    }

    const handleSelectTemplate = (item: any) => {

        setTemplateSelected(item);

        setPlaceholderTemplateApp(item.label);
        setTemplateSelected(item);
        setGeneralPrompt(item.data.instructions + ", " + item.data.output);
        onHandleSelectTemplate(item.data.instructions, item.data.output, item.value, item.label);

    }

    const handleSelectModel = async (item: any) => {

        let selected = selectedModels.find((element: any) => element[0] === item[0]);

        if (selected) {
            setSelectedModels(selectedModels.filter((element: any) => element[0] !== item[0]));
            setModelsSelected(selectedModels.filter((element: any) => element[0] !== item[0]));
        }
        else {
            if (selectedModels.length < 3) {
                setSelectedModels([...selectedModels, item]);
                setModelsSelected([...selectedModels, item]);
            }
        }

    }

    const handleResetChat = () => {
        setPlaceholderTemplateApp("Selecciona un template");
        onHandleResetChat();
    }

    useEffect(() => {

        if (dataTemplates) {
            setTemplates(dataTemplates?.templetesRecent?.map((item: any) => {
                return {
                    label: item.name,
                    value: item.id,
                    data: {
                        instructions: item.instructions,
                        output: item.output
                    }
                }
            }))
        }

        if (template?.name) {
            setPlaceholderTemplateApp(template.name);
        }

    }, [dataTemplates, template]);

    useEffect(() => {

        if (userState?.dataUser?.groups) {
            setGroups(userState.dataUser.groups.map((item: any) => {
                return {
                    label: item.name,
                    value: item.id
                }
            }));

            setGroupSelected(userState.dataUser.groupActiveId ? userState.dataUser.groups.find((item: any) => item.id === userState.dataUser.groupActiveId).name : "Selecciona un grupo");

        }

        if (userState?.dataUser?.akActive) {

            if (defaultModels.length != 0) {

                setSelectedModels(defaultModels.map((item: any) => {
                    return [item.id]
                }));
                setModelsSelected(defaultModels.map((item: any) => {
                    return [item.id]
                }));

                let place = "";
                defaultModels.forEach((element: any) => {
                    let name: any = models.find((model: any) => model.value === element.id);
                    if (name)
                        place += name.label + ", ";
                }
                );

                // quitamos la ultima coma
                place = place.substring(0, place.length - 2);

                setPlaceholderModel(place);

                return;
            }

            // poner el modelo activo
            if (selectedModels.length === 0) {

                setSelectedModels(userState?.dataUser?.akActive ? [[userState?.dataUser?.akActive.id]] : []);
                setModelsSelected(userState?.dataUser?.akActive ? [[userState?.dataUser?.akActive.id]] : []);
                setPlaceholderModel(() => userState?.dataUser?.akActive ? userState?.dataUser?.akActive.name : "Selecciona un modelo");
            }

            (async () => {

                // insertamos los modelos (apiKeys)
                const { data: dataModels } = await getModels();
                setModels(dataModels.apiKeysByGroup.map((item: any) => {
                    return {
                        label: item.name,
                        value: item.id
                    }
                }))
            })();


        }

    }, [userState]);

    useEffect(() => {
        let place = "";

        if (selectedModels.length === 0) {
            setPlaceholderModel("Selecciona un modelo");

            return;
        }


        if (selectedModels.length > 0 && models.length > 0) {
            selectedModels.forEach((element: any) => {
                let name: any = models.find((model: any) => model.value === element[0]);
                if (name)
                    place += name.label + ", ";
            }
            );

            place = place.substring(0, place.length - 2);

            setPlaceholderModel(place);
        }

    }, [selectedModels]);

    useEffect(() => {
        if (defaultModels.length != 0) {

            setSelectedModels(defaultModels.map((item: any) => {
                return [item.id]
            }));
            setModelsSelected(defaultModels.map((item: any) => {
                return [item.id]
            }));

            let place = "";
            defaultModels.forEach((element: any) => {
                let name: any = models.find((model: any) => model.value === element.id);
                if (name)
                    place += name.label + ", ";
            }
            );

            // quitamos la ultima coma
            place = place.substring(0, place.length - 2);

            setPlaceholderModel(place);

            return;
        }
    }, [defaultModels]);


    return (
        <View className="md:flex w-[100%] flex-col justify-center items-center">
            <View className="flex items-center justify-center  md:justify-between flex-row md:mt-3" style={{ width: "80%" }}>
                <View className="flex flex-row items-center justify-between">
                    <Text className="text-white text-base font-semibold mr-3">Grupo </Text>
                    {
                        loadingActiveGroup ? (
                            <View className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white" />
                        ) : (

                            <View className=" w-56">
                                <DropdownComponent
                                    data={groups}
                                    onChange={handleSelectGroup}
                                    placeholder={groupSelected}

                                />
                            </View>
                        )
                    }

                </View>


                <View className="hidden md:flex flex-row items-center justify-center">
                    <Text className="text-white text-base font-semibold mr-3">Template </Text>
                    {
                        loadingActiveGroup || loadingModels || loadingTemplates ? (
                            <View className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white" />

                        ) : (
                            <View className=" w-56">

                                <DropdownComponent
                                    data={templates}
                                    onChange={handleSelectTemplate}
                                    placeholder={placeholderTemplateApp}
                                />

                            </View>
                        )
                    }

                </View>

                <View className="hidden md:flex flex-row items-center justify-center">
                    <Text className="text-white text-base font-semibold mr-3">Modelo </Text>

                    {
                        loadingActiveGroup || loadingModels || loadingTemplates || loadingActiveApiKey ? (
                            <View className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white" />

                        ) : (
                            <View className=" w-56">

                                <ChecksDropdownComponent
                                    data={models}
                                    onChange={handleSelectModel}
                                    placeholder={placeholderModel}
                                    selectedItems={selectedModels}
                                />

                            </View>
                        )
                    }

                </View>


                <View className="flex justify-center items-center">

                    <Button
                        mode="text"
                        onPress={handleResetChat}

                        className="hover:transform hover:scale-110 transition duration-100 ease-in-out"
                    >
                        <TouchableRipple
                            onPress={handleResetChat}
                        >
                            <Image
                                source={require('../../../../assets/refresh.svg')}
                                style={{ width: 25, height: 25 }}
                                tintColor={"#D9D9D9"}
                            />
                        </TouchableRipple>
                    </Button>

                </View>

            </View>


            <View style={{ width: "100%" }} className="mt-3">
                <Divider bold className="text-white" />
            </View>
        </View>

    )
}