import { gql } from "@apollo/client";


export const QUERY_USER = gql`
    query {
        myUser {
            id
            email
            name
            lastName
            validationPhone
            validationEmail
            groupActiveId

            akActive{
                id
                name
                provider
                
            }

            groups {
                name
                id
                subscription{
                    plan{
                    daysHistorial
                    }
                }
            }
        }
    }
`;