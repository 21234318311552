import { View } from 'react-native';
import ItemMenu from "./ItemMenu";
import UserContext from "../../../../context/user/UserContext";
import { useContext, useState } from 'react';
import { Button, Dialog, Portal, PaperProvider, Text } from 'react-native-paper';
import ModalLayout from '../../../components/ui/Modal';
import AlertDialog from '../../../components/ui/AlertDialog';
import React from 'react';

interface MenuProps {
    navigation?: any;
    showRecent?: any;
    showMenu?: boolean;
    setShowMenu?: any;
    showHeaders?: any;
    resetData?: any;
}

export default function Menu({ navigation, showRecent, showMenu, setShowMenu, showHeaders, resetData }: MenuProps) {

    const { logout } = useContext(UserContext);

    const [visible, setVisible] = useState(false);

    const showDialog = () => setVisible(true);


    const handleLogout = async () => {
        resetData();
        await logout();
        navigation.navigate('Home');

        // limpiamos 
    }

    return (
        <>
            <View style={{ width: showMenu ? '' : '65px', backgroundColor: "#27374D" }} className={`h-screen ${showMenu ? 'absolute top-0 left-0 z-50' : 'hidden'} md:block`}>

                <View className="flex items-center justify-between h-full">

                    {
                        showMenu && (
                            <View style={{ height: "10%" }} className="mt-2">
                                <ItemMenu
                                    title='Cerrar Menu'
                                    source={require('../../../../assets/menu-left.svg')}
                                    onPress={() => setShowMenu(false)}
                                    color='#fff'
                                />
                            </View>

                        )
                    }

                    {/* {
                    showHeaders && (
                        <View style={{ height: "10%" }}>
                            <ItemMenu
                                title='Cerrar Headers'
                                source={require('../../../../assets/menu-left.svg')}
                                onPress={() => showHeaders(false)}
                                color='#fff'
                            />
                        </View>
                    )
                } */}



                    <View className={`flex items-center justify-center ${showMenu ? "" : "pt-28"}`}>

                        <ItemMenu
                            title='IA'
                            source={require('../../../../assets/ia.svg')}
                            onPress={() => {
                                setShowMenu(false)
                                navigation.navigate('App', { type: 'current' })
                            }}
                        />

                        <ItemMenu
                            title='Recientes'
                            source={require('../../../../assets/history.svg')}
                            onPress={() => {
                                setShowMenu(false)
                                showRecent();
                            }}
                        />


                        <ItemMenu
                            title='Templates'
                            testID={'menu-item-templates'}
                            source={require('../../../../assets/template.svg')}
                            onPress={() => {
                                setShowMenu(false)
                                navigation.navigate('Templates')
                            }}
                        />



                        <ItemMenu
                            title='Perfil'
                            source={require('../../../../assets/profile.svg')}
                            onPress={() => {
                                setShowMenu(false)
                                navigation.navigate('Profile')
                            }}
                        />

                        <ItemMenu
                            title='Metricas'
                            source={require('../../../../assets/metrics.svg')}
                            onPress={() => {
                                setShowMenu(false)
                                navigation.navigate('Metrics')
                            }}
                        />
                    </View>


                    <View >

                        <ItemMenu
                            title='Cerrar sesion'
                            testID={'menu-item-logout'}
                            source={require('../../../../assets/logout.svg')}
                            onPress={showDialog}
                        />
                    </View>

                </View>



            </View>

            <AlertDialog
                showDialog={visible}
                setShowDialog={setVisible}
                OnClickAction={handleLogout}
                text={`¿Quieres realmente salir? Tu sesión actual se cerrará.`}
                type='accept'
            />
        </>

    )
}