import { View, Text } from "react-native";
import { Button } from "react-native-paper";
import ModalLayout from "./Modal";
import React from "react";

interface AlertDialogProps {
    showDialog: boolean;
    setShowDialog: (show: boolean) => void;
    OnClickAction: () => void;
    text: string;
    loading?: boolean;
    type?: 'accept' | 'delete';
}

export default function AlertDialog({ showDialog, setShowDialog, OnClickAction, text, loading = false, type = 'delete'}: AlertDialogProps) {

    return (
        <ModalLayout
            visible={showDialog}
            onDismiss={() => setShowDialog(false)}
        >
            <View className="flex flex-col items-center justify-center">
                <Text className="text-base text-white text-center">{text}</Text>

                {
                    !loading && (

                        <View
                            className="flex items-center justify-between mt-5 flex-row w-[100%] md:w-[70%]"
                        >

                            <Button
                                mode="contained"
                                style={{ backgroundColor: "#6C757D" }}
                                textColor="#000000"
                                onPress={() => setShowDialog(false)}
                            >
                                Cancelar
                            </Button>

                            {
                                type === 'accept' && (
                                    <Button
                                        testID={'alert-accept-button'}
                                        mode="contained"
                                        textColor="#004225"
                                        style={{ backgroundColor: "#36AE7C" }}
                                        onPress={OnClickAction}
                                    >
                                        Aceptar
                                    </Button>
                                )
                            }

                            {
                                type === 'delete' && (
                                    <Button
                                        mode="contained"
                                        style={{ backgroundColor: "#FCAEAE" }}
                                        textColor="#FF3434"
                                        onPress={OnClickAction}
                                    >
                                        Eliminar
                                    </Button>
                                )
                            }

                        </View>
                    )

                }



            </View>

        </ModalLayout>
    )
}