import { gql } from "@apollo/client";

export const GET_TEMPLATES = gql`
    query {
        templetesRecent{
            id
            name
            instructions
            output
            user{
                id
            }
            group{
                id
                name
            }
        }
    }
`;

export const GET_TEMPLATES_BY_GROUP = gql`
    query  {
        templatesByGroup{
            id
            name
            instructions
            output
            user{
                id
            }
            group{
                id
                name
            }
        }
    }
`;

export const SEARCH_TEMPLATES = gql`
    query searchTemplates($search: String!) {
        searchTemplates(search: $search){
            id
            name
            instructions
            output
            user{
                id
            }
            group{
                id
                name
            }
        }
    }
`;

export const GET_ACTIVE_GROUP = gql`
    query activeGroup {
        groupActive{
            id
            name
            description
        }
    }
`;