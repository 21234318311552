import { useFormik } from "formik";

import Layout from "../layout/Main";
import React, { useEffect, useState } from "react";
import { StyleSheet, View, Text, TextInput, ScrollView } from "react-native";
import { useMutation, useQuery } from "@apollo/client";
import {
  API_KEY_ACTIVE,
  GET_INVITATIONS_AND_USERS_BY_GROUP,
  GET_USER_PERMISSIONS,
  GROUPS_BY_USER,
  GROUP_API_KEYS,
  USER_API_KEYS,
} from "./graphql/query";
import {
  ACTIVE_API_KEY,
  ACTIVE_GROUP,
  CREATE_API_KEY,
  CREATE_GROUP,
  CREATE_INVITATION,
  DELETE_GROUP,
  EDIT_GROUP,
  UPDATE_API_KEY,
} from "./graphql/mutation";
import { useToast } from "react-native-toast-notifications";
import { GET_ACTIVE_GROUP } from "../templates/graphql/query";
import TableGroups from "./components/TableGroups";
import TableApiKeys from "./components/TableApiKeys";
import TableMembers from "./components/TableMembers";
import TitleView from "../../components/ui/TitleView";

export default function MainTemplates({ navigation }: any) {
  const [groups, setGroups] = useState([]);
  const [apiKeys, setApiKeys] = useState([]);


  const { data: dataGroups, loading: loadingGroups, error: errorGroups } = useQuery(GROUPS_BY_USER, { fetchPolicy: "network-only" });
  const { data: dataActive, loading: loadingActive, error: errorActive } = useQuery(GET_ACTIVE_GROUP);
  const [createGroup, { data: dataCreateGroup, loading: loadingCreateGroup, error: errorCreateGroup }] = useMutation(CREATE_GROUP,
    {
      refetchQueries: [
        {
          query: GROUPS_BY_USER,
        },
      ],
    });
  const [updateGroup, { data: dataUpdateGroup, loading: loadingUpdateGroup, error: errorUpdateGroup }] = useMutation(EDIT_GROUP);
  const [activeGroup, { data: dataActiveGroup, loading: loadingActiveGroup, error: errorActiveGroup }] = useMutation(ACTIVE_GROUP,
    {
      refetchQueries: [
        {
          query: GET_ACTIVE_GROUP,
        },
        {
          query: API_KEY_ACTIVE
        },
        {
          query: GROUP_API_KEYS,
        },
        {
          query: GET_INVITATIONS_AND_USERS_BY_GROUP,
        },
        {
          query: GET_USER_PERMISSIONS,
        }
      ],
    });
  const { data: dataApiKeys, loading: loadingApiKeys, error: errorApiKeys } = useQuery(USER_API_KEYS);

  const [createApiKey, { data: dataCreateApiKey, loading: loadingCreateApiKey, error: errorCreateApiKey }] = useMutation(CREATE_API_KEY,
    {
      refetchQueries: [
        {
          query: GROUP_API_KEYS,
        },
      ],

    });
  const { data: activateApiKey, loading: loadingActivateApiKey, error: errorActivateApiKey } = useQuery(API_KEY_ACTIVE, { fetchPolicy: "network-only" });
  const [deleteGroup, { data: dataDeleteGroup, loading: loadingDeleteGroup, error: errorDeleteGroup }] = useMutation(DELETE_GROUP,
    {
      refetchQueries: [
        {
          query: GROUPS_BY_USER,
        },
      ],

    });
  const { data: dataPermissions, loading: loadingPermissions, error: errorPermissions } = useQuery(GET_USER_PERMISSIONS, { fetchPolicy: "network-only" });


  const toast = useToast();

  const handleActivateGroup = async (item: any) => {
    try {
      const { data } = await activeGroup({
        variables: {
          groupId: item.id,
        },
      });

      toast.show(data.activeGroup.message, {
        type: data.activeGroup.success ? "success" : "danger",
        placement: "top",
        animationType: "zoom-in",
        style: {
          borderRadius: 15,
        },
      });
    } catch (error) { }
  };

  const handleEditButton = async (item: any) => {
    try {
      const { data } = await updateGroup({
        variables: {
          groupId: item.id,
          name: item.name,
          description: "",
        },
      });

      toast.show(data.editGroup.message, {
        type: data.editGroup.success ? "success" : "danger",
        placement: "top",
        animationType: "zoom-in",
        style: {
          borderRadius: 15,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };


  const newGroupFormik = useFormik({
    initialValues: {
      name: "",
    },
    validate: (values) => {
      const errors: any = {};
      if (!values.name) {
        errors.nombre = "Required";
      }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        const { data } = await createGroup({
          variables: {
            name: values.name,
            description: "hola",
          },
        });

        toast.show(data.createGroup.message, {
          type: data.createGroup.success ? "success" : "danger",
          placement: "top",
          animationType: "zoom-in",
          style: {
            borderRadius: 15,
          },
        });

        newGroupFormik.resetForm();
      } catch (error) {
        console.log(error);
      }
    },
  });

  useEffect(() => {
    if (dataGroups) {
      setGroups(dataGroups.groupsByUser);
    }

    if (dataApiKeys) {
      setApiKeys(dataApiKeys.userApiKeys);
    }

  }, [dataGroups, dataApiKeys]);

  if (errorCreateApiKey) {
    console.log(errorCreateApiKey.message);

    toast.show(errorCreateApiKey.message, {
      type: "danger",
      placement: "top",
      animationType: "zoom-in",
      style: {
        borderRadius: 15,
      },
    });
  }

  return (

    <Layout
      navigation={navigation}
      title="Perfil"
    >
      <ScrollView
        style={{
          width: "100%",
          height: "100%",
        }}
        className="mb-5"
      >

        <View style={{ width: "100%", height: "98%", paddingLeft: '10%', paddingRight: '10%' }} >



          <TitleView title="Perfil" />

          <View style={{ width: "100%" }} className="mt-8">
            <Text className="text-xl text-white font-bold">Grupo</Text>
            <Text className="text-base text-white mt-3 mb-3">
              Comparte y controla los templetes dentro de tus grupos colaborativos.
            </Text>
          </View>

          <View style={{ width: "100%", height: "auto" }}>
            <TableGroups
              createGroup={createGroup}
              toast={toast}
              loadingGroups={loadingGroups}
              groups={groups}
              setGroups={setGroups}
              loadingUpdateGroup={loadingUpdateGroup}
              loadingActiveGroup={loadingActiveGroup}
              loadingDeleteGroup={loadingDeleteGroup}
              dataActive={dataActive}
              handleActivateGroup={handleActivateGroup}
              handleEditButton={handleEditButton}
              loadingCreateGroup={loadingCreateGroup}
              deleteGroup={deleteGroup}
              // buscamos el app que sea igual a "templates"

              permissions={dataPermissions?.userPermissions.filter((item: any) => item.app === "GROUPS")[0]}
            />
          </View>


          {dataActive?.groupActive.name && (
            <>

              <View
                style={{ width: "100%" }}
                className="mt-8"
              >
                <Text className="text-xl text-white font-bold">
                  Miembros del grupo - {dataActive?.groupActive.name}
                </Text>
                <Text className="text-base text-white mt-3 mb-3">
                  Administra los miembros del grupo para gestionar los temples y API keys del grupo.
                </Text>
              </View>

              <View style={
                { width: "100%", height: "auto" }
              }>
                <TableMembers
                  groupId={dataActive?.groupActive.id}
                  toast={toast}
                  permissions={dataPermissions?.userPermissions.filter((item: any) => item.app === "USERS")[0]}
                />
              </View>
            </>
          )}

          <View style={{ width: "100%" }} className="mt-8">
            <Text className="text-xl text-white font-bold">API keys</Text>
          </View>

          <Text className="text-base text-white mt-3 mb-3">
            Administra las API keys de los modelos disponibles.
          </Text>

          <View className="w-[100%]">
            <TableApiKeys
              createApiKey={createApiKey}
              toast={toast}
              loadingCreateApiKey={loadingCreateApiKey}
              activateApiKey={activateApiKey}
              permissions={dataPermissions?.userPermissions.filter((item: any) => item.app === "APIKEYS")[0]}

            />
          </View>

        </View>
      </ScrollView>
    </Layout>

  );
}
