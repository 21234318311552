import { Text, View } from 'react-native';
import { Icon } from 'react-native-paper';
import { useState } from 'react';

import LoginForm from '../components/LoginForm';
import SignupForm from '../components/SignupForm';
import RecoveryPassword from '../components/RecoveryPassword';


export default function Welcome({ navigation }: any) {

    const [isFormLogin, setIsFormLogin] = useState(true);
    const [recoveryPassword, setRecoveryPassword] = useState(false);

    return (
        <View className="grid h-screen lg:grid-cols-2">
            <View className="items-center justify-center hidden lg:flex" style={{ backgroundColor: "#526D82" }}>
                <Text className="text-2xl italic text-white" style={{ fontFamily: "Major Mono Display" }}>tus iA's favoritas en un solo lugar</Text>

                <View className="items-start">
                    <View className="flex flex-row justify-center items-center p-4 ">
                        <Icon
                            source="book"
                            color={"white"}
                            size={35}
                        />
                        <Text className="text-lg pl-3 text-white">Agrega tus documentos y extrae informacion de estos</Text>
                    </View>

                    <View className="flex flex-row justify-center items-center p-4 ">
                        <Icon
                            source="chat"
                            color={"white"}
                            size={35}
                        />
                        <Text className="text-lg pl-3 text-white">Crea tus propios templates personalizados</Text>
                    </View>

                    <View className="flex flex-row justify-center items-center p-4 ">
                        <Icon
                            source="share"
                            color={"white"}
                            size={35}
                        />
                        <Text className="text-lg pl-3 text-white">Comparte tus templates y chats a tus grupos</Text>
                    </View>

                    <View className="flex flex-row justify-center items-center p-4 ">
                        <Icon
                            source="robot"
                            color={"white"}
                            size={35}
                        />
                        <Text className="text-lg pl-3 text-white">Intercambia entre las IA's más comunes</Text>
                    </View>
                </View>
            </View>

            <View style={{ backgroundColor: "black" }} className="flex items-center justify-center">
                <Text className="text-3xl italic text-white font-semibold" style={{ fontFamily: "Roboto" }}>¡ Bienvenido !</Text>

                {
                    isFormLogin ?
                        (
                            recoveryPassword ?
                                <RecoveryPassword
                                    navigation={navigation}
                                    setRecoveryPassword={setRecoveryPassword}
                                /> :
                                <LoginForm
                                    navigation={navigation}
                                    setTypeForm={setIsFormLogin}
                                    typeForm={isFormLogin}
                                    setRecoveryPassword={setRecoveryPassword}
                                />
                        ) :
                        <SignupForm
                            navigation={navigation}
                            setTypeForm={setIsFormLogin}
                            typeForm={isFormLogin}
                        />
                }
            </View>
        </View>
    )
}