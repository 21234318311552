import { ApolloClient, HttpLink, ApolloProvider, InMemoryCache } from "@apollo/client";
import { config } from "../../config";
import { setContext } from "@apollo/client/link/context";
import { auth } from "../../firabaseConfig";

export default function ApolloProv({ children }: { children: React.ReactNode }) {

    const authLink = setContext(async (_, { headers }) => {

        const token = await auth.currentUser?.getIdToken();

        return {
            headers: {
                ...headers,
                authorization: token ? `JWT ${token}` : '',
            },
        };
    }
    );

    const client = new ApolloClient({
        link: authLink.concat(new HttpLink({
            uri: `${config.API_URL}/graphql`,
        })),
        cache: new InMemoryCache({
            addTypename: false,
        })
    });


    return (
        <ApolloProvider client={client}>
            {children}
        </ApolloProvider>
    )
}