import { View } from "react-native";


export default function LoaderScreen() {

    return (
        // animacion al aparecer la pantalla de carga
        <View className="flex items-center justify-center h-screen" style={{ backgroundColor: "#526D82", position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 999}}>
                <View className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-white">
            </View>
        </View>
    )
}