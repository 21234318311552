import { StyleSheet, View, Text, TextInput, ScrollView } from "react-native";
import AntDesign from "@expo/vector-icons/AntDesign";
import { MultiSelect } from "react-native-element-dropdown";
import { useEffect, useRef, useState } from "react";
import React from "react";
import { Checkbox } from "react-native-paper";

interface DropdownComponentProps {
    data: any[];
    onChange: (item: any) => void;
    placeholder?: string;
    notTable?: boolean;
    selectedItems: any[];
}

export const ChecksDropdownComponent = ({ data, onChange, placeholder, notTable, selectedItems }: DropdownComponentProps) => {

    const [isFocus, setIsFocus] = useState(false);
    const renderPlaceholder = () => {
        
        if (notTable) {
            return placeholder ? placeholder : "Seleccionar";
        } else {
            return placeholder ? placeholder.length > 20 ? placeholder?.substring(0, 20) + "..." : placeholder : "Seleccionar";
        }
    }
    const [newData, setNewData] = useState(data)

    useEffect(() => {

        const newData = data.map((item) => {
            return {
                label: item.label,
                value: item.value,
                checked: selectedItems.some((selected) => selected[0] === item.value)
            }
        }
        );
        setNewData(newData);
    }, [data, selectedItems]);


    return (
        <MultiSelect
            value={[]}
            renderItem={(item: any) => {
                return (
                    <View style={{
                        padding: 10,
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        backgroundColor: "#526D82",
                        margin: 5,
                    }} >
                        <Checkbox
                            status={item.checked ? 'checked' : 'unchecked'}
                            color="white"
                        />
                        <Text style={{ color: "white" }}>{item.label}</Text>
                    </View>
                );
            }
            }
            placeholder={renderPlaceholder()}
            data={newData}
            valueField={"value"}
            labelField={"label"}
            onChange={onChange}
            onFocus={() => setIsFocus(true)}
            onBlur={() => setIsFocus(false)}
            placeholderStyle={{ color: "white" }}
            itemTextStyle={{ color: "white" }}
            selectedTextStyle={{ color: "white" }}
            activeColor="#27374D"
            style={{
                width: "100%",
                height: 40,
                borderRadius: 8,
                borderWidth: 1,
                borderColor: "white",
                backgroundColor: "transparent",
                padding: 10,
            }}
            containerStyle={{
                borderRadius: 8,
                borderWidth: 1,
                borderColor: "white",
                backgroundColor: "#526D82",
            }}
            maxHeight={300}
            search
            inputSearchStyle={{ width: "100%" }}
            searchField="label"
            searchPlaceholder="Buscar..."
            renderInputSearch={(onSearch: (text: string) => void) => {
                return (
                    <View
                        className="flex flex-row justify-center items-center"
                        style={{
                            width: "100%",
                            backgroundColor: "transparent",
                            borderRadius: 8,
                            borderWidth: 1,
                            borderColor: "white",
                            padding: 10,
                        }}
                    >
                        <TextInput
                            placeholder="Buscar..."
                            placeholderTextColor="white"
                            onChangeText={(text) => {
                                onSearch(text);
                            }}
                            className="outline-none text-white w-full text-base"
                        />
                        <AntDesign name="search1" style={{ color: "white", fontSize: 15 }} />
                    </View>
                );
            }}
            renderRightIcon={() => (
                <AntDesign
                    name={isFocus ? "caretup" : "caretdown"}
                    style={{ color: "white" }}
                />
            )}
            selectedStyle={{ backgroundColor: "#70d7c7" }}

        />
    )
}