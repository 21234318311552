import { View, StyleSheet, TextInput as NativeTextInput, Keyboard } from 'react-native';
import { TextInput } from 'react-native-paper';
import LoaderInput from '../../../components/ui/LoaderInput';
import { useState, memo, useRef } from 'react';
import React from 'react';

const styles = StyleSheet.create({
    
    textInput: {
        backgroundColor: '#D9D9D9',
        paddingLeft: 15,
        width: '100%'
    }
});
interface InputChatProps {
    loading: boolean;
    startStream: boolean;
    handlePauseResponse: any;
    handleSendMessage: any;
    formikMessage: any;
    setLines: any;
    lines: number;
}

const InputChat = ({
    loading,
    startStream,
    handlePauseResponse,
    handleSendMessage,
    formikMessage,
    setLines,
    lines
}: InputChatProps) => {

    const [message, setMessage] = useState('');
    const inputRef = useRef<NativeTextInput|null>(null);

    const handleInputChange = (text: string) => {
        const lineCount = text.split('\n').length;
        if (lineCount < 9) setLines(lineCount);
    };

    const handleKeyPress = (e: any) => {
        if (e.nativeEvent.key === "Enter" && !e.nativeEvent.shiftKey) {
            e.preventDefault();
            handleSendMessage();
        }
        if (e.nativeEvent.key === "Enter" && e.nativeEvent.shiftKey && lines < 9) {
            setLines(lines + 1);
        }
    };

    return (
        startStream ? (
            <LoaderInput />
        ) : (
            <View className="flex justify-center items-center w-[80%] pb-3">
                <TextInput
                    ref={inputRef}
                    right={loading ? (
                        <TextInput.Icon
                            icon="pause"
                            onPress={handlePauseResponse}
                            color={'#000000'}
                        />
                    ) : (
                        <TextInput.Icon
                            icon="send"
                            testID='button-send-message'
                            onPress={() => {
                                // setLines(1);
                                handleSendMessage();
                            }}
                            color={'#000000'}
                        />
                    )}
                    mode='flat'
                    autoFocus={true}
                    placeholder='Escribe tu mensaje...'
                    testID='input-message'
                    style={styles.textInput}
                    textColor='#000000'
                    textAlignVertical='top'
                    multiline={true}
                    numberOfLines={lines}
                    placeholderTextColor='#000000'
                    underlineColor='#526D82'
                    activeUnderlineColor='#526D82'
                    disabled={loading}
                    value={formikMessage.values.message}
                    onChange={(e: any) => {
                        formikMessage.setFieldValue('message', e.nativeEvent.text);
                        setMessage(e.nativeEvent.text);
                        const lineCount = e.nativeEvent.text.split('\n').length;
                        setLines(lineCount <= 9 ? lineCount : 9);
                        if (e.nativeEvent.text.length < message.length) handleInputChange(e.nativeEvent.text);
                    }}
                    onKeyPress={handleKeyPress}
                />
            </View>
        )
    );
};

export default memo(InputChat);