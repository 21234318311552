import { gql } from "@apollo/client";


export const CREATE_TEMPLATE = gql`
    mutation createTemplate($groupId: String!, $name: String!, $instructions: String!, $output: String!, $isPublic: Boolean!) {
        createTemplate(groupId: $groupId, name: $name, instructions: $instructions, output: $output, isPublic: $isPublic) {
            message,
            success,
            template {
                id
                name
                output
                instructions
            
            }
        }
    }
`;

export const UPDATE_TEMPLATE = gql`
    mutation updateTemplate($templateId: ID!, $name: String!, $instructions: String!, $output: String!, $groupId: String!) {
        updateTemplate(templateId: $templateId, name: $name, instructions: $instructions, output: $output, groupId: $groupId) {
            message,
            success
        }
    }
`;

export const DELETE_TEMPLATE = gql`
    mutation deleteTemplate($templateId: ID!) {
        deleteTemplate(templateId: $templateId) {
            message,
            success
        }
    }
`;