import React, { useEffect, useState } from "react";
import { Dimensions, Text, View } from "react-native";

interface TitleViewProps {
    title: string;
}

export default function TitleView({ title }: TitleViewProps) {

    const [isHeight, setIsHeight] = useState(false);

    useEffect(() => {
        setIsHeight(Dimensions.get('window').height > 1000);
    }, [Dimensions.get('window').height])

    return (
        <View style={{ width: "100%" }} className={`justify-center ${isHeight ? 'mt-12' : 'mt-5'} hidden md:block`}>
            <Text className="text-2xl text-white " style={{ fontFamily: "Nunito" }}>{title}</Text>
        </View>
    )
}