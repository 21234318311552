import React, { useState } from 'react';
import { StyleSheet, View, Text, TextInput } from 'react-native';
import AntDesign from '@expo/vector-icons/AntDesign';
import { Dropdown } from 'react-native-element-dropdown';

interface DropdownComponentProps {
    data: any[];
    onChange: (item: any) => void;
    placeholder?: string;
    switchTheme?: boolean;
}

export const DropdownComponent = ({ data, onChange, placeholder, switchTheme }: DropdownComponentProps) => {
    const [isFocus, setIsFocus] = useState(false);

    return (
        <Dropdown
            value={""}
            placeholder={placeholder || "Seleccionar"}
            data={data}
            valueField={"value"}
            labelField={"label"}
            onChange={onChange}
            onFocus={() => setIsFocus(true)}
            onBlur={() => setIsFocus(false)}
            placeholderStyle={styles.placeholder}
            itemTextStyle={styles.itemText}
            selectedTextStyle={styles.selectedText}
            activeColor={"#27374D"}
            style={styles.dropdown}
            containerStyle={[styles.container, { backgroundColor: switchTheme ? "#27374D" : "#526D82" }]}
            maxHeight={300}
            search
            inputSearchStyle={styles.inputSearch}
            searchField="label"
            searchPlaceholder="Buscar..."
            renderInputSearch={(onSearch: (text: string) => void) => (
                <View
                    className="flex flex-row justify-center items-center"
                    style={styles.searchContainer}
                >
                    <TextInput
                        placeholder="Buscar..."
                        placeholderTextColor="white"
                        onChangeText={onSearch}
                        className="outline-none text-white w-full text-base"
                    />
                    <AntDesign name="search1" style={{ color: "white", fontSize: 15 }} />
                </View>
            )}
            renderRightIcon={() => (
                <AntDesign
                    name={isFocus ? 'caretup' : 'caretdown'}
                    style={{ color: 'white' }}
                />
            )}
            renderItem={(item) => (
                <View style={styles.item}>
                    <Text numberOfLines={1} ellipsizeMode="tail" style={styles.text}>
                        {item.label}
                    </Text>
                </View>
            )}
        />
    );
}

const styles = StyleSheet.create({
    placeholder: {
        color: "white",
        flexShrink: 1,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: 15,
    },
    itemText: {
        color: "white",
        flexShrink: 1,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    selectedText: {
        color: "white",
        flexShrink: 1,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    dropdown: {
        width: '100%',
        height: 40,
        borderRadius: 8,
        borderWidth: 1,
        borderColor: 'white',
        backgroundColor: 'transparent',
        padding: 10,
        overflow: 'hidden',
    },
    container: {
        borderRadius: 8,
        borderWidth: 1,
        borderColor: 'white',
    },
    inputSearch: {
        width: '100%',
    },
    searchContainer: {
        width: '100%',
        backgroundColor: 'transparent',
        borderRadius: 8,
        borderWidth: 1,
        borderColor: 'white',
        padding: 10,
        flexDirection: 'row',
    },
    item: {
        padding: 10,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    text: {
        fontSize: 16,
        color: 'white',
        numberOfLines: 1,
        ellipsizeMode: 'tail',
    }
});