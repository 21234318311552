import { useContext, useEffect, useState } from 'react';
import UserContext from '../../../context/user/UserContext';
import Welcome from "./screens/Welcome";
import LoaderScreen from '../../components/ui/LoaderScreen';
import { captureException } from "@sentry/react";
import React from 'react';


export default function MainHome({ navigation }: any) {

    const { userState, signInWithLink, loading, setLoading } = useContext(UserContext);

    useEffect(() => {
        async function checkLoginStatus() {
            try {
                const urlParams = new URLSearchParams(window.location.search);
                const apiKey = urlParams.get('apiKey');
                const oobCode = urlParams.get('oobCode');
                const mode = urlParams.get('mode');
                if (apiKey && oobCode && mode === 'signIn') {
                    const link = window.location.href;
                    window.history.replaceState({}, document.title, "/");
                    await signInWithLink(link);
                    const name = window.localStorage.getItem("n");
                    const lastName = window.localStorage.getItem("ln");
                    // Eliminamos los datos del localstorage si es necesario.
                    window.localStorage.removeItem("n");
                    window.localStorage.removeItem("ln");
                    navigation.navigate('App', { type: "complete", data: { name, lastName } });
                }
            } catch (error) {
                console.log(error);

                // captureException(error);
            } finally {

                if (userState?.email) {
                    navigation.navigate('App', { type: "refresh" });
                } else if (userState?.email == null) {
                    setLoading(false);
                }
            }
        }

        async function checkIvitationGroup() {
            try {
                const urlParams = new URLSearchParams(window.location.search);
                const mode = urlParams.get('mode');
                const invitationId = urlParams.get('invitation_id');
                const status = urlParams.get('status');
                if (mode === 'invitation' && invitationId && status) {
                    window.history.replaceState({}, document.title, "/");
                    navigation.navigate('App', { type: "invitation", data: { invitationId, status } });
                }
            } catch (error) {
                console.log(error);

            } finally {

            }
        }



        checkLoginStatus();

        if (userState.email) {
            checkIvitationGroup();

        }
    }, [userState.email]);

    return (
        <>
            {loading ? <LoaderScreen /> : <Welcome navigation={navigation} />}
        </>
    )
}