import { useContext, useState } from 'react';
import { Text, View, TouchableOpacity } from 'react-native';
import UserContext from '../../../../context/user/UserContext';
import { ActivityIndicator } from 'react-native-paper';
import { useToast } from "react-native-toast-notifications";
import React from 'react';


export default function ValidateNumber() {

    const { reSendEmail } = useContext(UserContext);

    const [loading, setLoading] = useState(false);

    const toast = useToast();

    const handleClickSendEmail = async () => {
        setLoading(true);

        try {
            await reSendEmail();
            toast.show("Se ha enviado nuevamente el correo de verificación",
                {
                    type: "success",
                    placement: 'top',
                    animationType: 'zoom-in',
                    style: {
                        borderRadius: 15,
                    },
                });

        } catch (error) {
            console.log(error);
        }

        setLoading(false);

    }


    return (
        <View className="w-screen h-screen flex items-center justify-center" style={{ backgroundColor: '#526D82' }}>
            <View className="p-10 bg-white rounded-2xl flex flex-col items-center justify-center">
                <Text className="text-2xl font-bold mb-2">¿Aun no verificas tu correo?</Text>
                <Text className="text-sm mb-4">Da click en el siguiente boton para reenviar nuevamente el correo</Text>
                <TouchableOpacity
                    className="w-11/12 h-10 bg-blue-500 rounded-3xl flex items-center justify-center mt-4"
                    onPress={handleClickSendEmail}
                    disabled={loading}
                >
                    {
                        loading ? (
                            <ActivityIndicator color="#fff" />
                        ) : (
                            <Text className="text-white text-sm">Validar</Text>
                        )
                    }
                </TouchableOpacity>
            </View>
        </View>

    )
}