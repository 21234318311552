import { useFormik } from "formik";
import { StyleSheet, View, Text, TextInput, ScrollView } from "react-native";
import { Image } from "react-native";
import { Button, TouchableRipple } from "react-native-paper";
import { useEffect, useState } from "react";
import { DropdownComponent } from "../../../components/forms/DropdownComponent";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { API_KEY_ACTIVE, GET_MODELS_IA, GET_MODELS_IA_BY_PROVIDER, GROUP_API_KEYS } from "../graphql/query";
import { ACTIVE_API_KEY, DELETE_API_KEY, UPDATE_API_KEY } from "../graphql/mutation";
import ModalLayout from "../../../components/ui/Modal";
import AlertDialog from "../../../components/ui/AlertDialog";
import React from "react";

interface TableApiKeysProps {
    createApiKey: any;
    toast: any;
    loadingCreateApiKey: boolean;
    activateApiKey: any;
    permissions: any;
}


export default function TableApiKeys({ activateApiKey, createApiKey, toast, loadingCreateApiKey, permissions }: TableApiKeysProps) {

    const [showDialog, setShowDialog] = useState(false);
    const [apiKeySelected, setApiKeySelected] = useState({} as any)
    const [apiKeys, setApiKeys] = useState([] as any);
    const [placeholder, setPlaceholder] = useState("Seleccionar");

    const { data: dataModels, loading: loadingModels } = useQuery(GET_MODELS_IA);
    const [deleteApiKey, { data: dataDeleteApiKey, loading: loadingDeleteApiKey }] = useMutation(DELETE_API_KEY,
        {
            refetchQueries: [
                {
                    query: GROUP_API_KEYS,
                },
            ],

        })
    const { data: dataGroupApiKeys, loading: loadingGroupApiKeys, error: errorGroupApiKeys } = useQuery(GROUP_API_KEYS);
    const [updateApiKey, { data: dataUpdateApiKey, loading: loadingUpdateApiKey, error: errorUpdateApiKey }] = useMutation(UPDATE_API_KEY,
        {
            refetchQueries: [
                {
                    query: GROUP_API_KEYS,
                },
            ],
        })
    const [activeApiKey, { data: dataActiveApiKey, loading: loadingActiveApiKey, error: errorActiveApiKey }] = useMutation(ACTIVE_API_KEY,
        {
            refetchQueries: [
                {
                    query: API_KEY_ACTIVE,
                },
            ],
        });

    const styles = StyleSheet.create({
        input: {
            padding: 10,
            flex: 1, // Para que cada input ocupe el espacio que le corresponde
            borderRightWidth: 1, // Añadimos un borde a la derecha de cada input
            borderColor: "#D9D9D9", // Color del borde
        },
        headerText: {
            display: "flex",
            fontWeight: "bold",
            color: "#27374D", // Color del texto
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#D9D9D9",
            padding: 3
        },

        headerTextRadiusLeft: {
            display: "flex",
            fontWeight: "bold",
            color: "#27374D", // Color del texto
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            borderTopLeftRadius: 40,
            backgroundColor: "#D9D9D9",
            padding: 3

        },
        headerTextRadiusRight: {
            display: "flex",
            fontWeight: "bold",
            color: "#27374D", // Color del texto
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            borderTopRightRadius: 40,
            backgroundColor: "#D9D9D9",
            padding: 3

        }
    });

    const [p, setP] = useState([
        {
            label: "OPENAI",
            value: "OPENAI",
        },
        {
            label: "GOOGLE",
            value: "GOOGLE",
        },
        {
            label: "ANTHROPIC",
            value: "ANTHROPIC",
        }
    ]);
    const [providerSelected, setProviderSelected] = useState("");

    const handleOnChage = async (item: any) => {

        setProviderSelected(item.value);

    }

    const handleSelectModel = (item: any) => {
        apiKeyFormik.setFieldValue("model", item.value);
        setPlaceholder(item.label);
    }

    const handleActivateApiKey = async (item: any) => {
        const response = await activeApiKey({
            variables: {
                apiKeyId: item.id
            }
        })

        toast.show(response.data.activeApiKey.message, {
            type: "success",
            placement: "top",
            animationType: "zoom-in",
            style: {
                borderRadius: 15,
            },
        });

    }

    const handleDeleteApiKey = async (item: any) => {
        setShowDialog(true);
        setApiKeySelected(item);

    }

    const handleSaveApiKey = async (item: any) => {

        const response = await updateApiKey({
            variables: {
                apiKeyId: item.id,
                key: item.mask,
                modelIaId: item.model.id || item.model.value,
                name: item.name
            }
        })

        toast.show(response.data.updateApiKey.message, {
            type: response.data.updateApiKey.success ? "success" : "danger",
            placement: "top",
            animationType: "zoom-in",
            style: {
                borderRadius: 15,
            },
        });
    }

    const onCLickDelete = async () => {
        const response = await deleteApiKey({
            variables: {
                apiKeyId: apiKeySelected.id
            }
        })

        toast.show(response.data.deleteApiKey.message, {
            type: response.data.deleteApiKey.success ? "success" : "danger",
            placement: "top",
            animationType: "zoom-in",
            style: {
                borderRadius: 15,
            },
        });

        setShowDialog(false);
    }

    const onCLickCancel = () => {
        setShowDialog(false);
    }

    const apiKeyFormik = useFormik({
        initialValues: {
            name: "",
            model: "",
            apiKey: "",
        },
        validate: (values) => {
            const errors: any = {};
            if (!values.name) {
                errors.nombre = "Required";
            }

            if (!values.model) {
                errors.model = "Required";
            }

            if (!values.apiKey) {
                errors.apiKey = "Required";
            }

            return errors;
        },
        onSubmit: async (values) => {

            const { data } = await createApiKey({
                variables: {
                    name: values.name,
                    model: values.model,
                    key: values.apiKey
                }
            });

            toast.show(data.createApiKey.message, {
                type: "success",
                placement: "top",
                animationType: "zoom-in",
                style: {
                    borderRadius: 15,
                },
            });

            apiKeyFormik.resetForm();


        },
    });

    useEffect(() => {
        if (dataGroupApiKeys) {
            setApiKeys(dataGroupApiKeys.groupApiKeys);
        }
    }, [dataGroupApiKeys]);


    return (
        <ScrollView horizontal className="w-[100%]" >
            <View
                style={{ borderTopLeftRadius: 40, borderTopRightRadius: 40 }}
                className="w-[100%]"
            >
                <View className="flex flex-col w-[100%]">

                    <View className="grid grid-cols-apiKeys w-[auto] md:w-[75vw]">

                        {/* header */}
                        <Text style={styles.headerTextRadiusLeft}>Nombre</Text>
                        <Text style={styles.headerText}>Marca</Text>
                        <Text style={styles.headerText}>Modelo</Text>
                        <Text style={styles.headerText}>Api Key</Text>
                        <Text style={styles.headerText}>Estatus</Text>
                        <Text style={styles.headerText}>Activar</Text>
                        <Text style={styles.headerText}>Guardar</Text>
                        <Text style={styles.headerTextRadiusRight}>Borrar</Text>

                        {
                            !loadingGroupApiKeys && (
                                <>

                                    {
                                        apiKeys?.map((item: any, index: any) => (
                                            < >
                                                <TextInput
                                                    style={styles.input}
                                                    value={item.name}
                                                    className={`${activateApiKey?.apiKeyActive?.id === item.id ? 'font-bold' : ''} bg-[#27374D] border-b text-[#D9D9D9] `}
                                                    onChangeText={(text) => {
                                                        const newApiKeys: any = apiKeys.map((apiKey: any) => ({
                                                            ...apiKey
                                                        }));

                                                        newApiKeys[index].name = text;
                                                        setApiKeys(newApiKeys);

                                                    }}
                                                />

                                                <View className="flex justify-center bg-[#27374D] border-r border-b border-[#D9D9D9]">

                                                    <DropdownComponent
                                                        data={p}
                                                        onChange={(model: any) => {

                                                            const newApiKeys: any = apiKeys.map((apiKey: any) => ({
                                                                ...apiKey
                                                            }));

                                                            newApiKeys[index].provider = model.value;
                                                            setApiKeys(newApiKeys);


                                                        }}
                                                        placeholder={item.provider}
                                                    />
                                                </View>


                                                <View className="flex items-center justify-center bg-[#27374D] border-r border-[#D9D9D9] text-[#D9D9D9]">
                                                    <DropdownComponent
                                                        // filtramos los modelos por el provider
                                                        data={dataModels?.getModelsIa?.filter((modelItem: any) => modelItem.provider === item.provider).map((modelItem: any) => ({
                                                            label: modelItem.model,
                                                            value: modelItem.id
                                                        }))}
                                                        onChange={(model: any) => {

                                                            const newApiKeys: any = apiKeys.map((apiKey: any) => ({
                                                                ...apiKey
                                                            }));

                                                            newApiKeys[index].model = {
                                                                id: model.value,
                                                                model: model.label,
                                                            }

                                                            setApiKeys(newApiKeys);

                                                        }}
                                                        placeholder={item.model.model}
                                                    />
                                                </View>


                                                <TextInput
                                                    style={styles.input}
                                                    value={item.mask}
                                                    className={`bg-[#27374D] border-b text-[#D9D9D9] `}
                                                    onChangeText={(text) => {
                                                        const newApiKeys: any = apiKeys.map((apiKey: any) => ({
                                                            ...apiKey
                                                        }));

                                                        newApiKeys[index].mask = text;
                                                        setApiKeys(newApiKeys);

                                                    }}
                                                />

                                                <View className="flex pl-3 flex-row items-center  bg-[#27374D] border-r border-b border-[#D9D9D9]">

                                                    {
                                                        item.status === 1 && (
                                                            <>
                                                                <Image
                                                                    source={require('../../../../assets/success.svg')}
                                                                    style={{ width: 20, height: 20 }}
                                                                    tintColor={"#36AE7C"}
                                                                />

                                                                <Text className="text-[#D9D9D9] pl-2"> Activo </Text>
                                                            </>
                                                        )
                                                    }

                                                </View>

                                                <View className="flex justify-center items-center flex-row bg-[#27374D] border-r border-b border-[#D9D9D9]">

                                                    {
                                                        loadingActiveApiKey ? (
                                                            <View className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white" />
                                                        ) : (
                                                            <Button
                                                                mode="text"
                                                                onPress={() => { handleActivateApiKey(item) }}

                                                                className="hover:transform hover:scale-110 transition duration-100 ease-in-out"
                                                            >
                                                                <TouchableRipple
                                                                    onPress={() => { handleActivateApiKey(item) }}
                                                                >
                                                                    <Image
                                                                        source={require('../../../../assets/send.svg')}
                                                                        style={{ width: 20, height: 20 }}
                                                                        tintColor={"#D9D9D9"}
                                                                    />
                                                                </TouchableRipple>
                                                            </Button>
                                                        )
                                                    }

                                                </View>

                                                <View className="flex justify-center items-center flex-row bg-[#27374D] border-r border-b border-[#D9D9D9]">

                                                    {

                                                        permissions?.update && (

                                                            loadingUpdateApiKey ? (
                                                                <View className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white" />
                                                            ) : (
                                                                <Button
                                                                    mode="text"
                                                                    onPress={() => { handleSaveApiKey(item) }}

                                                                    className="hover:transform hover:scale-110 transition duration-100 ease-in-out"
                                                                >
                                                                    <TouchableRipple
                                                                        onPress={() => { handleSaveApiKey(item) }}
                                                                    >
                                                                        <Image
                                                                            source={require('../../../../assets/save.svg')}
                                                                            style={{ width: 20, height: 20 }}
                                                                            tintColor={"#D9D9D9"}
                                                                        />
                                                                    </TouchableRipple>
                                                                </Button>
                                                            )
                                                        )
                                                    }


                                                </View>

                                                <View className="flex justify-center items-center flex-row bg-[#27374D]  border-b border-[#D9D9D9]">

                                                    {
                                                        permissions?.delete && (
                                                            loadingDeleteApiKey ? (
                                                                <View className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white" />
                                                            ) : (
                                                                <Button
                                                                    mode="text"
                                                                    onPress={() => { handleDeleteApiKey(item) }}

                                                                    className="hover:transform hover:scale-110 transition duration-100 ease-in-out"
                                                                >
                                                                    <TouchableRipple
                                                                        onPress={() => { handleDeleteApiKey(item) }}
                                                                    >
                                                                        <Image
                                                                            source={require('../../../../assets/delete.svg')}
                                                                            style={{ width: 20, height: 20 }}
                                                                            tintColor={"#D9D9D9"}
                                                                        />
                                                                    </TouchableRipple>
                                                                </Button>
                                                            )

                                                        )
                                                    }


                                                </View>

                                            </>
                                        ))
                                    }

                                    {
                                        permissions?.create && (
                                            <>
                                                <TextInput
                                                    style={styles.input}
                                                    placeholder="Nombre del AK"
                                                    value={apiKeyFormik.values.name}
                                                    onChangeText={apiKeyFormik.handleChange('name')}
                                                    className="bg-[#27374D] border-r border-[#D9D9D9] text-[#D9D9D9]"
                                                />

                                                <View className="flex items-center justify-center bg-[#27374D] border-r border-[#D9D9D9] text-[#D9D9D9]">
                                                    <DropdownComponent
                                                        data={p}
                                                        onChange={handleOnChage}
                                                    />
                                                </View>

                                                <View className="flex items-center justify-center bg-[#27374D] border-r border-[#D9D9D9] text-[#D9D9D9]">

                                                    {
                                                        loadingModels ? (
                                                            <View className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white" />
                                                        ) : (
                                                            <DropdownComponent
                                                                data={dataModels?.getModelsIa?.filter((modelItem: any) => modelItem.provider === providerSelected).map((modelItem: any) => ({
                                                                    label: modelItem.model,
                                                                    value: modelItem.id
                                                                }))}
                                                                onChange={handleSelectModel}
                                                                placeholder={placeholder}
                                                            />
                                                        )
                                                    }

                                                </View>

                                                <TextInput
                                                    style={styles.input}
                                                    placeholder="Api Key"
                                                    value={apiKeyFormik.values.apiKey}
                                                    onChangeText={apiKeyFormik.handleChange('apiKey')}
                                                    className="bg-[#27374D] border-r border-[#D9D9D9] text-[#D9D9D9]"
                                                />

                                                <View className="flex items-center justify-center bg-[#27374D] border-r border-[#D9D9D9] text-[#D9D9D9]">
                                                </View>

                                                <View className="flex items-center justify-center bg-[#27374D] border-r border-[#D9D9D9] text-[#D9D9D9]">
                                                </View>

                                                <View className="flex justify-center items-center flex-row bg-[#27374D] border-r border-[#D9D9D9] ">


                                                    {
                                                        loadingCreateApiKey ? (
                                                            <View className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white" />
                                                        ) : (
                                                            Object.keys(apiKeyFormik.errors).length === 0 && (
                                                                <Button
                                                                    mode="text"
                                                                    onPress={apiKeyFormik.handleSubmit}
                                                                    className="hover:transform transition duration-100 ease-in-out"
                                                                >
                                                                    <TouchableRipple
                                                                        onPress={apiKeyFormik.handleSubmit}
                                                                    >
                                                                        <Image
                                                                            source={require('../../../../assets/send.svg')}
                                                                            style={{ width: 20, height: 20 }}
                                                                            tintColor={"#D9D9D9"}
                                                                        />
                                                                    </TouchableRipple>
                                                                </Button>
                                                            )
                                                        )
                                                    }


                                                </View>

                                                <View className="flex items-center justify-center bg-[#27374D] border-[#D9D9D9] text-[#D9D9D9]">
                                                    <Text className="">  </Text>
                                                </View>

                                            </>
                                        )
                                    }


                                </>

                            )
                        }




                    </View>

                </View>
            </View>

            <AlertDialog
                showDialog={showDialog}
                setShowDialog={setShowDialog}
                OnClickAction={onCLickDelete}
                text={`Eliminar este elemento es irreversible. ¿Quieres continuar?`}
                loading={loadingDeleteApiKey}
            />

        </ScrollView>
    )

}