import React from 'react';
import { View, Text, ScrollView } from 'react-native';
import styled from 'styled-components/native'; 

const Wrapper = styled.View`
  /* Estilos para el contenedor principal */
  flex: 1;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 16px;
`;

const Pre = styled.ScrollView`
  /* Estilos para la sección de código */
  text-align: left;
  margin: 1em 0;
  padding: 0.5em;
  background-color: #2d2d2d; /* Color de fondo del bloque de código */
  border-bottom-rigth-radius: 16px;
`;

const Line = styled.View`
  /* Estilos para una línea de código */
  flex-direction: row;
`;


const LineContent = styled.Text`
  /* Estilos para el contenido de la línea */
  color: #d4d4d4; /* Color del texto del código */
`;

export { Wrapper, Pre, Line,  LineContent };
