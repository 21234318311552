import { Text, View, ScrollView } from 'react-native';
import { TouchableRipple, Tooltip } from 'react-native-paper';
import { formatInitial } from '../../../utils/formater';
import React from 'react';
import Loader from '../../../components/ui/Loader';
import { Tooltip as T } from "@material-tailwind/react";

interface TemplatesListProps {
    setTemplate: any;
    setChatStart: any;
    loading: any;
    templates: any;
    messageChat: Array<any>;
    setMessages: any;
    setType?: any;
    setGeneralPrompt?: any;
}

export default function TemplatesList({ setTemplate, setChatStart, loading, templates, messageChat, setMessages, setType, setGeneralPrompt }: TemplatesListProps) {

    const selectTemplate = async (template: any) => {
        setTemplate(template);
        setChatStart(true);

        // liampiamos el chat
        messageChat.push({
            role: "system",
            subMessages: [
                {
                    id: null,
                    content: `${template.instructions}, ${template.output}`,
                    provider: ''
                }
            ]
        });

        setMessages(messageChat)

        setGeneralPrompt(`${template.instructions}, ${template.output}`);

        setType('reset_template')
    }

    return (


        loading ? (
            <View className=" h-[70%]">
                <Loader />
            </View>
        ) : (

            <ScrollView
                scrollEventThrottle={16}
            >
                <View className="grid grid-cols-1 gap-5  md:grid-cols-2 lg:grid-cols-3" style={{ width: "100%" }}>

                    {
                        templates?.length == 0 ? (
                            <Text className="text-white text-base font-semibold">No hay ningun templates creado</Text>
                        ) : (

                            templates?.map((template: any) => (
                                <TouchableRipple
                                    key={template.id}
                                    onPress={() => selectTemplate(template)}
                                >
                                    <View
                                        key={template.id}
                                        className="p-5 flex flex-row text-ellipsis overflow-hidden hover:transform hover:scale-105 transition duration-200 cursor-pointer ease-in-out"
                                        style={{ backgroundColor: "#D9D9D9", borderRadius: 20 }}
                                    >

                                        <View style={{ width: "100%" }}>
                                            <View className="flex flex-row items-center">

                                                {
                                                    template?.group && (
                                                        <View
                                                            className="flex items-center justify-center text-lg mr-2"
                                                            style={{ height: "2rem", width: "2rem", background: '#27374D', borderBottomEndRadius: 10, borderTopEndRadius: 10, borderTopStartRadius: 10 }}
                                                        >
                                                            <Tooltip
                                                                title={template?.group?.name}
                                                                enterTouchDelay={0}
                                                                leaveTouchDelay={500}
                                                            >

                                                                <Text
                                                                    className="font-semibold text-base" style={{ color: '#D9D9D9' }}
                                                                >
                                                                    {formatInitial(template?.group?.name)}
                                                                </Text>
                                                            </Tooltip>
                                                        </View>

                                                    )
                                                }
                                                <Text className="text-lg overflow-ellipsis overflow-hidden whitespace-nowrap" style={{ color: "#27374D" }} >{template.name}</Text>
                                            </View>

                                            <T content={
                                                <View className="p-2 bg-white rounded-lg" style={{
                                                    maxWidth: 300
                                                }}>


                                                    <Text className="text-base" style={{ color: "#595959" }}>{template.instructions}</Text>

                                                </View>

                                            } placement="bottom-start"
                                            >
                                                <Text className="text-base mt-1 overflow-ellipsis overflow-hidden whitespace-nowrap" style={{ color: "#595959" }}>{template.instructions}</Text>
                                            </T>

                                        </View>
                                    </View>
                                </TouchableRipple>
                            ))
                        )
                    }

                </View>

            </ScrollView>
        )

    )
}