import React, { useContext, useEffect, useRef, useState } from "react";
import { ScrollView, View } from "react-native";
import LayoutMain from "../../layout/Main";
import UserContext from "../../../../context/user/UserContext";
import HeaderApp from "../components/HeaderApp";
import SearchTemplateInput from "../../../components/ui/SearchTemplateInput";
import TemplatesList from "../components/TemplatesList";
import { Button, Divider, TouchableRipple } from "react-native-paper";
import InputChat from "../components/InputChat";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { GET_TEMPLATES } from "../../templates/graphql/query";
import ChatComponent from "../components/ChatComponent";
import { useFormik } from "formik";
import { CREATE_CHAT, SAVE_MESSAGE_INPUT } from "../graphql/mutation";
import { auth } from '../../../../firabaseConfig';
import { io } from "socket.io-client";
import { config } from "../../../../config";
import { API_KEYS_BY_GROUP } from "../../profile/graphql/query";
import { useToast } from "react-native-toast-notifications";
import { captureException, captureMessage } from "@sentry/react";

interface IChat {
    navigation: any;
    route: any;
}

const messageChat = [] as any;
let chatIdPrivate = null as any;
let socket = null as any;
let attempts = 0;

export default function Chat({ navigation, route }: IChat) {

    const { userState } = useContext(UserContext);
    const { params } = route;
    const toast = useToast() as any;
    const chatRef = useRef<ScrollView>(null);

    const [chatStart, setChatStart] = useState(false);
    const [messages, setMessages] = useState([] as any);
    const [template, setTemplate] = useState({} as any);
    const [templates, setTemplates] = useState([] as any);
    const [type, setType] = useState('');
    const [generalPrompt, setGeneralPrompt] = useState('');
    const [chatId, setChatId] = useState(null);
    const [placeholderTemplate, setPlaceholderTemplate] = useState("Selecciona un template");
    const [modelsSelected, setModelsSelected] = useState([] as any);
    const [defaultModels, setDefaultModels] = useState([] as any);
    const [startStream, setStartStream] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState(false);
    const [lines, setLines] = useState(1);


    const [getTemplates, { loading: loadingTemplates, data: dataTemplates }] = useLazyQuery(GET_TEMPLATES);
    const [newChat, { loading: loadingChat }] = useMutation(CREATE_CHAT);
    const [saveMessageInput, { loading: loadingSendMessage }] = useMutation(SAVE_MESSAGE_INPUT);
    const [getApiKeys, { data: dataApiKeys, loading: loadingApiKeys, error: errorApiKeys }] = useLazyQuery(API_KEYS_BY_GROUP);

    const handleResetChat = () => {
        resetData();
    }

    const handleSelectTemplate = (instructions: string, output: string, templateId: string, label: string) => {

        handleResetChat();

        messageChat.push({
            role: "system",
            subMessages: [
                {
                    id: null,
                    content: `${instructions}, ${output}`,
                    provider: userState?.dataUser.akActive.provider
                }
            ]
        });

        setMessages([...messageChat]);

        setChatStart(true);
        setTemplate({ id: templateId, name: label });
        setType('reset_template');
        setGeneralPrompt(`${instructions}, ${output}`);
    }

    const resetData = () => {
        setLoadingMessage(false);
        setStartStream(false);
        setChatStart(false);
        setMessages([]);
        messageChat.splice(0, messageChat.length);
        setChatId(() => null);
        setTemplate({});
        setType('reset');
        socket?.close();
        socket = null;
        setGeneralPrompt('');
    }

    const handleSendMessage = async () => {

        if (!formikMessage.values.message) return;

        // verificamos si tiene algun modelo seleccionado
        if (modelsSelected.length === 0) {

            if (toast.state.toasts.length === 0) {
                toast.show("Selecciona al menos un modelo para continuar",
                    {
                        type: "danger",
                        placement: 'top',
                        animationType: 'zoom-in',
                        style: {
                            borderRadius: 15,
                        },
                        duration: 5000,
                    }
                );
            }

            return;
        }

        setStartStream(true);

        const token = await auth.currentUser?.getIdToken();

        // Creamos el socket
        socket = io(config.SOCKET_URL, {
            auth: {
                token: `${token}`,
            },
            reconnectionAttempts: 3,
            reconnectionDelay: 5000,
        });

        // verificamos si hay conexion
        socket.on('connect_error', (err: any) => {
            attempts++;

            if (attempts > 3) {
                toast.show("No se ha podido establecer conexión con el servidor, intente más tarde",
                    {
                        type: "danger",
                        placement: 'top',
                        animationType: 'zoom-in',
                        style: {
                            borderRadius: 15,
                        },
                    }
                );

                socket?.close();
                socket = null;
                setStartStream(false);
                attempts = 0;

            }


        });

        socket.on('connect', async () => {


            if (!chatId || params?.type === "reset") {

                if (params?.type === "reset") {

                    setChatId(() => null);

                    params.type = "current";
                }

                setChatStart(true);

                const { data } = await newChat({
                    variables: {
                        templateId: template.id || '',
                        message: formikMessage.values.message
                    }
                });


                setChatId(() => data.newChat.chat.id);
                chatIdPrivate = data.newChat.chat.id;
                formikMessage.setFieldValue('chatId', data.newChat.chat.id);

            }

            const apiKeys = modelsSelected.map((model: any) => model[0]);


            if (messageChat.length > 0 && messageChat[0].role === "system" && messageChat[0].subMessages[0].id === null) {

                const { data } = await saveMessageInput({
                    variables: {
                        chatId: chatId || chatIdPrivate,
                        content: messageChat[0].subMessages[0].content,
                        role: messageChat[0].role,
                        apiKeyId: userState?.dataUser.akActive?.id,
                        apiKeys: apiKeys
                    }
                });

                messageChat[0].subMessages[0].id = data.saveMessageInput.subMessageId;
            }


            messageChat.push({
                role: "user",
                subMessages: [
                    {
                        id: null,
                        content: formikMessage.values.message,
                        provider: userState?.dataUser.akActive?.provider
                    }
                ],
                isLast: true
            });


            const { data: dataSaveMessInput } = await saveMessageInput({
                variables: {
                    chatId: chatId || chatIdPrivate,
                    content: formikMessage.values.message,
                    role: "user",
                    apiKeyId: userState?.dataUser.akActive?.id,
                    apiKeys: apiKeys
                }
            });


            setLoadingMessage(true);

            const configsModel = [] as any;
            const subMessages = [] as any;

            modelsSelected.forEach((model: any) => {
                configsModel.push({ apiKeyId: model[0] });

                const provider = dataApiKeys.apiKeysByGroup.find((group: any) => group.id == model[0])?.provider;
                subMessages.push({ id: null, content: '', provider: provider, apiKeyId: model[0], selected: false });
            });

            const newHistory = [];


            for (let i = 0; i < messageChat.length; i++) {

                if (messageChat[i].role === "system")
                    continue;

                const subMessages = messageChat[i].subMessages;

                // buscamos el submessage que tenga selected en true
                const subMessage = subMessages.find((subMessage: any) => subMessage.selected);

                // si el role es system,
                newHistory.push({
                    role: messageChat[i].role,
                    content: subMessage ? subMessage.content : messageChat[i].subMessages[0].content,
                    provider: messageChat[i].subMessages[0].provider
                });
            }

            const dataSocket = {
                chatId: chatId || chatIdPrivate,
                inputSubMessageId: dataSaveMessInput.saveMessageInput.subMessageId,
                jwt: token,
                configModel: configsModel,
                messages: newHistory,
                generalPrompt: generalPrompt || null
            }

            socket.emit('send-message', dataSocket);

            // // guardamos el mensaje
            messageChat.push({ role: "assistant", subMessages });

            setMessages([...messageChat]);

            setLines(1);
            formikMessage.resetForm();
        });

        socket.on('receive-message', async (data: any) => {

            setStartStream(false);

            const lastIndex = messageChat.length - 1;

            for (let i = 0; i < messageChat[lastIndex].subMessages.length; i++) {

                if (messageChat[lastIndex].subMessages[i].apiKeyId == data.apiKeyId) {

                    if (!data.finish) {
                        setMessages((prevMessages: any) => {
                            const newMessages = [...prevMessages];
                            newMessages[lastIndex].subMessages[i].content += data.content;
                            return newMessages;
                        });
                    }

                }

            }

        });

        socket.on('response-save-message', async (data: any) => {

            // obtenemos el ultimo mensaje
            const lastIndex = messageChat.length - 1;

            // actualizamos el mensaje con el id que venga en el arreglo de objetos de data

            for (let i = 0; i < messageChat[lastIndex].subMessages.length; i++) {

                for (let j = 0; j < data.length; j++) {

                    if (messageChat[lastIndex].subMessages[i].apiKeyId == data[j].apiKeyId) {
                        messageChat[lastIndex].subMessages[i].id = data[j].submessageId;
                        messageChat[lastIndex].subMessages[i].messageId = data[j].messageId;
                    }

                }
            }

            setLoadingMessage(false);

            // // cerramos el socket 
            socket?.close();
            socket = null;
            setMessages(messageChat);

        });

        socket.on('error', (data: any) => {

            if (typeof data === 'object') {
                data = JSON.stringify(data);
            }
            captureMessage(data);
            console.log("error", data);

            const message = data.message || "Ha ocurrido un error, intente más tarde";

            toast.show(message,
                {
                    type: "danger",
                    placement: 'top',
                    animationType: 'zoom-in',
                    style: {
                        borderRadius: 15,
                    },
                }
            );

            socket?.close();
            socket = null;
            setLoadingMessage(false);
            setStartStream(false);

        });

        // por si hay una desconexion
        socket.on('disconnect', (data: any) => {

            if (data !== 'io client disconnect') {
                console.log("disconnect", data);
                return;
            }

            if (data === 'transport close') {
                toast.show("Se ha perdido la conexión con el servidor",
                    {
                        type: "danger",
                        placement: 'top',
                        animationType: 'zoom-in',
                        style: {
                            borderRadius: 15,
                        },
                    }
                );

                socket?.close();
                socket = null;
                setLoadingMessage(false);
            }



        });

    }

    const handlePauseResponse = () => {
        // socket?.emit('stop-messages');
    }

    const formikMessage = useFormik({
        initialValues: {
            message: "",
            chatId: null
        },
        validate: (values: any) => {
            const errors: any = {};

            if (!values.message) {
                errors.message = "El mensaje es requerido";
            }

            return errors;
        },
        onSubmit: async (values: any) => {
        },
    });

    const fetchTemplates = async () => {
        const { data } = await getTemplates();
        setTemplates(data.templetesRecent);
    }

    const fetchApiKeys = async () => {
        await getApiKeys();
    }

    useEffect(() => {
        if (params?.type === "history") {
            setGeneralPrompt('');

            setDefaultModels(params.apiKeys);

            const messagesHistory = params.messages.map((message: any) => {
                return {
                    role: message.role?.toLowerCase(),
                    subMessages: message.subMessages.map((subMessage: any) => {
                        return {
                            id: subMessage.id,
                            messageId: message.id,
                            content: subMessage.content,
                            provider: subMessage.apiKey.provider,
                            apiKeyId: subMessage.apiKey.id,
                            selected: subMessage.isSelected
                        }
                    }
                    )
                }
            });

            if (messagesHistory.length == 0) {
                return;
            }

            // obtenemos el primer mensaje
            const firstMessage = messagesHistory[0];

            setMessages(messagesHistory);

            setTimeout(() => {
                chatRef.current?.scrollToEnd({ animated: true });
            }, 1000);

            setChatId(params.chatId);

            messageChat.splice(0, messageChat.length);
            messageChat.push(...messagesHistory);

            if (firstMessage.role === "system") {
                setGeneralPrompt(firstMessage.subMessages[0].content);
            }

            setChatStart(true);
            setTemplate(params.template || {});
        } else if (params?.type === "template") {
            resetData();

            messageChat.push({
                role: "system",
                subMessages: [
                    {
                        id: null,
                        content: `${params.template.instructions}, ${params.template.output}`,
                        provider: userState?.dataUser.akActive.provider
                    }
                ]
            });

            setMessages(messageChat);
            setGeneralPrompt(`${params.template.instructions}, ${params.template.output}`);

            setTemplate(params.template);
            setChatStart(true);
        }


    }, [params]);

    useEffect(() => {
        fetchTemplates();
        fetchApiKeys();
    }, []);


    return (
        <LayoutMain
            navigation={navigation}
            onHandleSelectTemplate={handleSelectTemplate}
            template={template}
            placeholderTemplateApp={placeholderTemplate}
            setPlaceholderTemplateApp={setPlaceholderTemplate}
            resetData={resetData}
            menuRight={true}
        >

            <View className="items-center justify-between h-full w-full overflow-hidden">

                <HeaderApp
                    onHandleResetChat={handleResetChat}
                    onHandleSelectTemplate={handleSelectTemplate}
                    template={template}
                    setPlaceholderTemplateApp={setPlaceholderTemplate}
                    placeholderTemplateApp={placeholderTemplate}
                    setModelsSelected={setModelsSelected}
                    defaultModels={defaultModels}
                    setGeneralPrompt={setGeneralPrompt}
                />


                <View style={{ width: "100%"}} className="flex items-center pb-3 pt-3 h-[82%] md:h-[84%] lg:h-[80%] xl:h-[83%] overflow-hidden">

                    {
                        !chatStart ? (
                            <View className="flex flex-col h-full" style={{ width: "80%"}}>

                                <SearchTemplateInput
                                    loading={loadingTemplates}
                                    setSearchTemplate={setTemplates}
                                    templatesInitial={dataTemplates?.templates}
                                />

                                <TemplatesList
                                    setTemplate={setTemplate}
                                    setChatStart={setChatStart}
                                    loading={loadingTemplates}
                                    templates={templates}
                                    messageChat={messageChat}
                                    setMessages={setMessages}
                                    setType={setType}
                                    setGeneralPrompt={setGeneralPrompt}
                                />
                            </View>
                        ) : (
                            <View style={{ width: '100%', height: "90%" }} className="flex items-center justify-center">
                                <ChatComponent
                                    messageChat={messageChat}
                                    messages={messages}
                                    setMessages={setMessages}
                                    chatRef={chatRef}
                                />
                            </View>
                        )
                    }

                </View>

                <View style={{ width: "100%" }} className="flex items-center justify-center mb-3">


                    {
                        !userState?.dataUser?.akActive ? (
                            <Button
                                mode="contained"
                                textColor="white"
                                buttonColor="#36AE7C"
                                onPress={() => navigation.navigate("Profile")}
                            >
                                No tienes una API activa, da click para activarla
                            </Button>
                        ) : (
                            <InputChat
                                formikMessage={formikMessage}
                                handlePauseResponse={handlePauseResponse}
                                handleSendMessage={handleSendMessage}
                                startStream={startStream}
                                loading={loadingMessage}
                                setLines={setLines}
                                lines={lines}
                            />
                        )
                    }

                </View>


            </View>



        </LayoutMain>
    )
}