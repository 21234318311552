import { View, StyleSheet, Text, ScrollView } from 'react-native';
import Highlight, { defaultProps } from "prism-react-renderer";
import theme from "prism-react-renderer/themes/nightOwl";
import { Pre, Line, LineContent } from "./styles";
import HeaderCode from './HeaderCode';
import ReactMarkDown from "react-markdown";
import { useEffect, useState } from 'react';
import remarkGfm from 'remark-gfm'
import React from 'react';
import remarkBreaks from 'remark-breaks';
import Markdown from 'react-markdown';

interface CodeChatProps {
    message: string;
}


const styles = StyleSheet.create({
    table: {
        borderColor: '#000',
        width: '100%',
    },
    tableContainer: {
        flexDirection: 'column',
        width: '100%',
    },
    thead: {},
    tbody: {},
    tr: {
        flexDirection: 'row',
        width: '100%',
    },
    th: {
        flex: 1,
        padding: 8,
        backgroundColor: '#f0f0f0',
        borderWidth: 1,
        borderColor: '#000',
        textAlign: 'center',
        fontSize: 16,
        fontWeight: 'bold',
        width: '100%',


    },
    td: {
        flex: 1,
        padding: 5,
        borderWidth: 1,
        borderColor: '#000',
        color: '#ffffff',
        fontSize: 15,
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',

    },
    ul: {
        paddingLeft: 25,
        listStyleType: 'disc',
    },
    ol: {
        listStyleType: 'decimal',
        paddingLeft: 25,
    },
    li: {
        padding: 3,
    },
    pre: {
        fontFamily: 'Courier New',
        fontSize: 14,
        backgroundColor: '#282c34',
        color: '#abb2bf',
        padding: 10,
        borderRadius: 4,
    },
    line: {
        fontFamily: 'Courier New',
    },
    lineContent: {
        fontFamily: 'Courier New',
    },
});

const tableComponents = {
    table: ({ children }: any) => (
        <ScrollView horizontal={true} style={styles.table}>
            <View style={styles.tableContainer}>{children}</View>
        </ScrollView>
    ),
    thead: ({ children }: any) => <View style={styles.thead}>{children}</View>,
    tbody: ({ children }: any) => <View style={styles.tbody}>{children}</View>,
    tr: ({ children }: any) => <View style={styles.tr}>{children}</View>,
    th: ({ children }: any) => <Text style={styles.th}>{children}</Text>,
    td: ({ children }: any) => <Text style={styles.td}>{children}</Text>,
    ul: ({ children }: any) => <ul style={styles.ul}>{children}</ul>,
    ol: ({ children }: any) => <ol style={styles.ol}>{children}</ol>,
    li: ({ children }: any) => <li style={styles.li}>{children}</li>,
    h3: ({ children }: any) => <Text style={{ fontSize: 18, fontWeight: 'bold', color: 'white' }}>{children}</Text>,
    h2: ({ children }: any) => <Text style={{ fontSize: 20, fontWeight: 'bold', color: 'white' }}>{children}</Text>,
    h1: ({ children }: any) => <Text style={{ fontSize: 24, fontWeight: 'bold', color: 'white' }}>{children}</Text>,
};

const renderText = (text: string) => {
    const modifiedText = transformText(text);

    let outputText = modifiedText
        .replace(/^(&nbsp;(\s*)?)+/, '&nbsp;\n')
        .replace(/(&nbsp;(\s*)?)+$/, '\n&nbsp;')
        ;

    return (
        // <ReactMarkDown
        //     remarkPlugins={[remarkGfm, remarkBreaks]}
        //     components={tableComponents}
        // >
        //     {modifiedText}
        // </ReactMarkDown>

        <Markdown
            remarkPlugins={[remarkGfm, remarkBreaks]}
            components={tableComponents}
        >
            {outputText}
        </Markdown>

    );
};

const transformText = (text: string) => {
    const lines = text.split('\n');

    return lines.map((line, index) => {
        // Check if the line is part of a list
        const isListItem = /^\s*[*\-+]\s+|^\s*\d+\.\s+/.test(line);
        const isNextLineListItem = index < lines.length - 1 && /^\s*[*\-+]\s+|^\s*\d+\.\s+/.test(lines[index + 1]);

        if (isListItem || isNextLineListItem)
            return line;

        // if(line.trim() === '\\')
        //     return line.replace('\\', '&nbsp;');

        return line + '&nbsp;\n';
    }).join('\n');
}

const renderCodeBlock = (code: string) => {
    if (!code)
        return ("\n");

    const expresion_test = /^\n/;
    let lenguaje = "plaintext";
    let codigo = code;
    if (!expresion_test.test(code)) {
        const expresion = /(\w+)\n([\s\S]+)/;
        const coincidencia: any = code.match(expresion);
        lenguaje = coincidencia ? coincidencia[1] : "";
        codigo = coincidencia ? coincidencia[2] : "";
    }



    return (
        <View style={{ width: "100%" }} >
            <HeaderCode
                lenguage={lenguaje}
                code={codigo}
            />
            <Highlight
                {...defaultProps}
                theme={theme}
                code={codigo}
                language={lenguaje as any}
            >

                {({ className, style, tokens, getLineProps, getTokenProps }) => (
                    <Pre style={{ ...styles.pre, ...style }} className={className}>

                        {tokens.map((line, i) => (
                            <Line key={i} {...getLineProps({ line, key: i })} style={styles.line}>
                                <LineContent style={styles.lineContent}>
                                    {line.map((token, key) => (
                                        <span key={key} {...getTokenProps({ token })} />
                                    ))}
                                </LineContent>
                            </Line>
                        ))}
                    </Pre>
                )}
            </Highlight>
        </View>
    );
}

export default function renderMessage({ message }: CodeChatProps) {

    const [messageParts, setMessageParts] = useState([] as any);

    useEffect(() => {
        const parts = message.split('```');
        setMessageParts(parts);
    }, [message]);

    // Dividir el mensaje en fragmentos de código y texto normal

    return messageParts.map((part: any, index: number) => {
        if (index % 2 === 0)
            // Fragmento de texto normal
            return renderText(part);
        else {
            return renderCodeBlock(part);
        }
    });

};


