import { Modal, Portal, Divider, Switch } from "react-native-paper";
import { View, Text, ScrollView } from "react-native";
import React from "react";

interface ModalLayoutProps {
    visible: boolean;
    onDismiss: () => void;
    title?: string;
    children: any;
}

export default function ModalLayout({ visible, onDismiss, title = '', children }: ModalLayoutProps) {

    return (
        <Portal>
            <Modal
                visible={visible}
                onDismiss={onDismiss}
                className="flex items-center justify-center"
                dismissable={true}
                dismissableBackButton={false}
            >
                <View className="rounded-3xl w-[80%] md:w-[50%] lg:w-[40%] xl:w-[30%]" style={{ backgroundColor: "#27374D", height: "auto"}}>
                    <View className="flex items-center justify-center m-5" style={{ maxHeight: "100%" }}>

                        {
                            title && (

                                <>
                                    <Text className="text-white font-bold text-lg">
                                        {title}
                                    </Text>

                                    <View style={{ width: "100%" }} className="mt-5 mb-5">
                                        <Divider bold />
                                    </View>
                                </>

                            )
                        }

                        <View
                            style={{ width: "100%", height: "auto" }}
                        >
                            <ScrollView
                                contentContainerStyle={{ alignItems: "center" }}
                                style={{ maxHeight: "auto", width: '100%' }}
                            >

                                {children}

                            </ScrollView>

                        </View>

                    </View>
                </View>
            </Modal>

        </Portal>
    )
}