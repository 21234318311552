import React, { useContext, useEffect, useState } from 'react';
import { View, Text } from 'react-native';
import { Image } from 'react-native';
import UserContext from '../../../../context/user/UserContext';
interface IconProps {
  type: 'USER' | 'SYSTEM' | 'ASSISTANT';
  provider: string;
}

const PATH_ICON_IA: any = {
  OPENAI: require('../../../../assets/openia.svg'),
  GOOGLE: require('../../../../assets/google.svg'),
  ANTHROPIC: require('../../../../assets/anthropic.svg'),
}

export default function IconMessage({ type, provider }: IconProps) {

  const { userState } = useContext(UserContext);
  const [initials, setInitials] = useState('');

  useEffect(() => {
    if (userState?.dataUser?.name) {
      const name = userState?.dataUser?.name.toUpperCase();
      const lastName = userState?.dataUser?.lastName.toUpperCase();

      const initials = name[0] + lastName[0];
      setInitials(initials);
    }
  }, [userState?.dataUser?.name]);



  return (
    <View
      className="flex items-center justify-center text-lg"
      style={{ height: "2.5rem", width: "2.5rem", background: '#D9D9D9', color: '#526D82', borderBottomEndRadius: 10, borderTopEndRadius: 10, borderTopStartRadius: 10 }}
    >
      {
        type.toUpperCase() === ('USER') ? (
          <Text
            className="font-semibold text-lg" style={{ color: '#526D82' }}
          >
            {initials}
          </Text>
        ) : (
          <Image
            source={type.toUpperCase() == 'ASSISTANT' ? PATH_ICON_IA[provider.toUpperCase()] : require('../../../../assets/ia.svg')}
            style={{ width: 30, height: 30 }}
            tintColor={'#526D82'}
          />
        )
      }
    </View>

  );
};

