import { gql } from "@apollo/client";

export const COMPLETE_USER = gql`
    mutation CompleteUser($name: String!, $lastName: String!) {
        completeUser(name: $name, lastName: $lastName) {
            message
            user{
                validationEmail
            }
        }
    }
`;

export const SEND_MESSAGE = gql`
    mutation sendChat($messageChat: String!, $chatId: ID!) {
        sendChat(messageChat: $messageChat, chatId: $chatId) {
            message
        }
    }
`;

export const CREATE_CHAT = gql`
    mutation newChat($templateId: String, $message: String!) {
        newChat(templateId: $templateId, message: $message) {
            message
            chat {
                id
            }
        }
    }
`;

export const SAVE_MESSAGE = gql`
    mutation saveMessage($chatId: String!, $content: String!, $role: String!, $provider: String!){
        saveMessage(
            chatId: $chatId,
            content: $content,
            role: $role,
            provider: $provider
        ){
            message
        }
    }
`;

export const SAVE_MESSAGE_INPUT = gql`
    mutation saveMessageInput($apiKeyId: String!, $chatId: String!, $content: String!, $role: String!, $apiKeys: [String]!){
        saveMessageInput(
            apiKeyId: $apiKeyId,
            chatId: $chatId,
            content: $content
            role: $role
            apiKeys: $apiKeys
        ){
            message
            subMessageId
        }
    }
`;

export const ACCEPT_INVITATION = gql`
    mutation acceptInvitation($invitationId: ID!, $status: String!){
        acceptInvitation(
            invitationId: $invitationId,
            status: $status
        ){
            message
            success
        }
    }
`;

export const SELECT_SUB_MESSAGE = gql`
    mutation selectSubMessage( $subMessageId: String!){
        selectSubMessage(
            subMessageId: $subMessageId
        ){
            message
        }
    }
`;