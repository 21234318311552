import React from "react";
import { View } from "react-native";

interface LoaderProps {
    backgroundColor?: string;
}

export default function Loader({ backgroundColor }: LoaderProps) {
    return (
        <View className="flex items-center justify-center h-[100%]" style={{ backgroundColor: backgroundColor ? backgroundColor : "#526D82"}}>
            <View className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-white">
            </View>
        </View>
    )
}