import { gql } from "@apollo/client";


export const HISTORY_CHATS = gql`
    query historyChats($after: String, $templateId: String) {
        historyChats(first:15, after: $after, templateId: $templateId){
            pageInfo{
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
            edges{
                node{
                    id
                    firstMessage
                    template{
                        name
                    }
                    apiKeys{
                        id
                        name
                    }
                }
            }
        }
    }
`;

export const HISTORY_MESSAGES = gql`
    query historyMessages($chatId: String!) {
        historyMessages(chatId: $chatId){
            role
            id
            subMessages{
                id
                content
                apiKey{
                    id
                    provider
                }
                isSelected
            }
        }
    }
`;

export const TEMPLATES_HISTORY = gql`
    query{
        templatesHistory{
            id
            name
        }
    }
`