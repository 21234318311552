import { Text, View, ScrollView, Image } from 'react-native';
import { HISTORY_CHATS, HISTORY_MESSAGES, TEMPLATES_HISTORY } from "../graphql/query";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { TouchableRipple } from "react-native-paper";
import { useState, useEffect, useContext } from 'react';
import React from 'react';
import AlertDialog from '../../../components/ui/AlertDialog';
import { DELETE_CHAT } from '../graphql/mutation';
import { DropdownComponent } from '../../../components/forms/DropdownComponent';
import { captureException } from "@sentry/react";
import Loader from '../../../components/ui/Loader';
import LoaderScreen from '../../../components/ui/LoaderScreen';
import UserContext from '../../../../context/user/UserContext';

export default function HistoryChat({ navigation, showRecent }: any) {

    const { userState } = useContext(UserContext);

    const [optionsPagination, setOptionsPagination] = useState({} as any);
    const [chats, setChats] = useState([] as any);
    const [showAlertDelete, setShowAlertDelete] = useState(false);
    const [selectedChat, setSelectedChat] = useState({} as any);
    const [showMessageSubscription, setShowMessageSubscription] = useState(false);
    const [finish, setFinish] = useState(false);
    const [templatesOptions, setTemplatesOptions] = useState([{ value: '', label: "Todos" }]);
    const [loadingMessages, setLoadingMessages] = useState(false);

    const { data, loading, error } = useQuery(HISTORY_CHATS, { fetchPolicy: "network-only" });
    const { data: dataTemplatesHistory, loading: loadingTemplatesHistory, error: errorTemplatesHistory } = useQuery(TEMPLATES_HISTORY);

    const [getHistoryChats, { loading: loadingHistoryChats }] = useLazyQuery(HISTORY_CHATS, { fetchPolicy: "network-only" });
    const [getHistoryMessages] = useLazyQuery(HISTORY_MESSAGES);
    const [deleteChat, { loading: loadingDeleteChat }] = useMutation(DELETE_CHAT,
        {
            // actualizamos la lista de chats
            onCompleted: async () => {
                const { data } = await getHistoryChats();
                setOptionsPagination(data.historyChats.pageInfo);
                setChats(data.historyChats.edges);
                setFinish(false);

            }
        });

    const handleClickHistory = async (chat: any) => {

        setLoadingMessages(true);
        const { data } = await getHistoryMessages({ variables: { chatId: chat.id } });
        setLoadingMessages(false);

        showRecent();

        navigation.navigate('App', {
            messages: data?.historyMessages,
            chatId: chat.id,
            template: chat.template,
            apiKeys: chat.apiKeys,
            type: "history"
        });
    }

    const handleScroll = async ({ nativeEvent }: any) => {
        const isCloseToBottom = nativeEvent.layoutMeasurement.height + nativeEvent.contentOffset.y + 1 >= nativeEvent.contentSize.height;


        if (isCloseToBottom && !finish) {
            // hacemos la peticion para cargar mas chats
            if (!optionsPagination.hasNextPage) {
                setShowMessageSubscription(!userState.subscription);
                return;
            };

            const { data } = await getHistoryChats({ variables: { after: optionsPagination.endCursor } });

            setOptionsPagination(data.historyChats.pageInfo);
            setChats([...chats, ...data.historyChats.edges]);
        }
    };

    const handleClikDelete = async (data: any) => {
        setShowAlertDelete(true);
        setSelectedChat(data);
    };

    const handleDeleteChat = async () => {
        console.log(selectedChat);
        const { data } = await deleteChat({
            variables: {
                chatId: selectedChat.id
            }
        });

        setShowAlertDelete(false);
        setChats([]);
        setFinish(true);

    }

    const handleSelectFilterTemplate = async (item: any) => {

        const { data } = await getHistoryChats({
            variables: {
                templateId: item.value
            }
        })
        setOptionsPagination(data.historyChats.pageInfo);
        setChats(data.historyChats.edges);
        setFinish(false);

    }

    useEffect(() => {
        if (data) {
            setOptionsPagination(data.historyChats.pageInfo);
            setChats(data.historyChats.edges);
        }

        if (dataTemplatesHistory) {

            const newTemplatesOptions = dataTemplatesHistory.templatesHistory.map((template: any) => ({
                value: template.id,
                label: template.name
            }));

            newTemplatesOptions.unshift({ value: '', label: "Todos" });


            setTemplatesOptions(newTemplatesOptions)

        }
    }, [data, dataTemplatesHistory])

    if (error || errorTemplatesHistory) {
        captureException(error || errorTemplatesHistory);
        return <Text>Error :</Text>;
    }


    return (

        <>

            {
                loadingMessages ? (
                    <LoaderScreen />
                ) : (
                    <View className="h-screen transition w-screen sm:w-[300px] md:border-l-0 md:border-white md:left-[65px] left-0" style={{ zIndex: 1000, position: 'absolute', backgroundColor: "#27374D", borderLeftWidth: "1px" }}>

                        <View className="flex flex-row-reverse">
                            <TouchableRipple
                                onPress={showRecent}
                            >
                                <Image
                                    source={require('../../../../assets/close.svg')}
                                    style={{ width: 25, height: 25 }}
                                    className="hover:opacity-50 cursor-pointer mr-4 mt-4"
                                />
                            </TouchableRipple>
                        </View>

                        <View>
                            <Text className="text-white text-center text-xl font-semibold p-8 pt-0">Historial</Text>
                        </View>

                        <View className="flex justify-center items-center">
                            <View className="flex w-[60%] justify-center items-center mb-5">

                                {
                                    loadingTemplatesHistory ? (
                                        <Loader backgroundColor='#27374D' />
                                    ) : (

                                        <DropdownComponent
                                            data={templatesOptions}
                                            onChange={handleSelectFilterTemplate}
                                            placeholder="Filtrar por template"
                                            switchTheme={true}

                                        />

                                    )
                                }
                            </View>
                        </View>

                        {
                            loading ? (
                                <View className="items-center justify-center h-4/5">
                                    <View className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-white" />
                                </View>
                            ) : (
                                <ScrollView className="overflow-y-auto h-4/5"
                                    onScroll={handleScroll}
                                    scrollEventThrottle={50}
                                >
                                    {

                                        chats.length === 0 ? (
                                            <View className="flex flex-row justify-center items-center p-4 bg-gray-800 m-4 rounded-xl shadow-xl overflow-hidden">
                                                <Text className="text-white font-bold">No hay chats</Text>
                                            </View>
                                        ) : (
                                            <>

                                                {
                                                    chats?.map(({ node }: any, index: number) => {

                                                        return (
                                                            <TouchableRipple
                                                                key={index}
                                                                onPress={() => handleClickHistory(node)}
                                                            >

                                                                <View key={index} className="flex flex-row justify-between items-center p-4 bg-gray-800 m-4 rounded-xl shadow-xl overflow-hidden">
                                                                    <View className="flex flex-col" style={{ width: "80%" }}>
                                                                        <Text className="text-white font-bold">{node.template ? node.template.name : ''}</Text>
                                                                        <Text className="text-white overflow-ellipsis overflow-hidden whitespace-nowrap">{node.firstMessage ? node.firstMessage : 'Sin mensajes'}</Text>
                                                                    </View>
                                                                    <View className="flex justify-center items-center">
                                                                        <TouchableRipple
                                                                            onPress={() => handleClikDelete(node)}
                                                                        >
                                                                            <Image
                                                                                source={require('../../../../assets/delete.svg')}
                                                                                style={{ width: 20, height: 20 }}
                                                                                className="hover:opacity-50 cursor-pointer mr-4"
                                                                            />
                                                                        </TouchableRipple>
                                                                    </View>
                                                                    <View className="flex justify-center items-center">
                                                                        <TouchableRipple
                                                                        // onPress={() => handleClikDelete(node)}
                                                                        >
                                                                            <Image
                                                                                source={require('../../../../assets/send.svg')}
                                                                                style={{ width: 20, height: 20 }}
                                                                                className="hover:opacity-50 cursor-pointer mr-4"
                                                                            />
                                                                        </TouchableRipple>
                                                                    </View>
                                                                </View>
                                                            </TouchableRipple>
                                                        )

                                                    })
                                                }

                                                {
                                                    showMessageSubscription && (
                                                        <View className="flex flex-row justify-center items-center p-4 overflow-hidden">
                                                            <Text className="text-white font-bold text-center text-base">Activa una subscripcion para visualizar todo tu historial</Text>
                                                        </View>
                                                    )
                                                }



                                            </>

                                        )
                                    }

                                    {
                                        loadingHistoryChats && (
                                            <View className="items-center justify-center">
                                                <View className="animate-spin rounded-full h-20 w-20 border-t-2 border-b-2 border-white" />
                                            </View>
                                        )
                                    }
                                </ScrollView>
                            )
                        }

                        <AlertDialog
                            showDialog={showAlertDelete}
                            setShowDialog={setShowAlertDelete}
                            OnClickAction={handleDeleteChat}
                            text={`Se eliminara el chat, de forma permanente`}
                            type='accept'
                        />

                    </View >
                )
            }


        </>

    )
}