import { gql } from "@apollo/client";


export const GROUPS_BY_USER = gql`
    query {
        groupsByUser{
            id
            name
            description
            owner{
                name
            }
            users{
                name
            }
            subscription{
                plan{
                    name
                }
            }
        }
    }
`;

export const USERS_BY_GROUP = gql`
    query groupUsers($groupId: ID!){
        groupUsers(id: $groupId){
            id
            name
            email
        }
    }
`;

export const USER_API_KEYS = gql`
    query {
        userApiKeys{
            id
            name
            mask
            provider
            model{
                id
                model
            }
        }
    }
`;

export const GROUP_API_KEYS = gql`
    query {
        groupApiKeys{
            id
            name
            mask
            provider
            status
            model{
                id
                model
            }
        }
    }
`;

export const API_KEYS_BY_GROUP = gql`
    query apiKeysByGroup($groupId: ID){
        apiKeysByGroup(id: $groupId){
            id
            name
            mask
            provider
            status
            model{
                id
                model
            }
        }
    }
`;

export const API_KEY_ACTIVE = gql`
    query {
        apiKeyActive {
            id
            provider
            name
            model{
                model
            }
        }
    }
`;

export const GET_MODELS_IA = gql`
    query {
        getModelsIa {
            id
            provider
            model
        }
    }
`;

export const GET_MODELS_IA_BY_PROVIDER = gql`
    query modelsIaByProvider($provider: String!){
        modelsIaByProvider(provider: $provider){
            id
            provider
            model
        }
    }
`;

export const GET_ROLES = gql`
    query {
        roles{
            id
            type
            label
        }
    }
`;

export const GET_INVITATIONS_BY_GROUP = gql`
    query invitationsByGroup($groupId: ID!){
        invitationsByGroup(id: $groupId){
            id
            name
            email
            status
            role{
                label
                id
            }
        }
    }
`;

export const GET_INVITATIONS_AND_USERS_BY_GROUP = gql`
    query {
        invitationsUsersByGroup{

            users{
                id
                name
                email
                role{
                    id
                    label
                }
            }
        
            invitations{
                id
                name
                email
                status
                role{
                    id
                    label
                }
            }
        }
    }
`;

export const GET_USER_PERMISSIONS = gql`
    query {
        userPermissions{
            id
            app
            create
            update
            delete
        }
    }
`;