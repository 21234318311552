import { useFormik } from "formik";
import Modal from "../../../components/ui/Modal";
import { Button, TextInput, Switch, HelperText } from "react-native-paper";
import { View, Text } from "react-native";
import { useToast } from "react-native-toast-notifications";
import { useEffect } from "react";
import React from "react";
import { Tooltip } from "@material-tailwind/react";

interface TemplateFormProps {
    showForm: boolean;
    onDismiss: () => void;
    isEdit: boolean;
    template?: any;
    group: any;
    updateTemplate: any;
    loading: boolean;
    createTemplate: any;
    handleClickSelect: any;
}

export default function TemplateForm({ showForm, onDismiss, isEdit, template, group, updateTemplate, loading, createTemplate, handleClickSelect }: TemplateFormProps) {

    const toast = useToast();

    const formikTemplate = useFormik({
        initialValues: {
            name: "",
            instructions: "",
            output: "",
            shareGroup: false,
            groupId: ""
        },
        validate: (values) => {
            let errors: any = {};
            if (!values.name) {
                errors.name = 'El nombre es requerido';
            }
            if (!values.instructions) {
                errors.instructions = 'Las instrucciones son requeridas';
            }
            if (!values.output) {
                errors.output = 'El output es requerido';
            }
            return errors;
        },
        onSubmit: async (values) => {
            let message = "";
            let templateForm = template;
            try {

                const groupId = values.shareGroup ? group.id : "";

                if (isEdit) {

                    const { data } = await updateTemplate({
                        variables: {
                            templateId: template.id,
                            name: values.name,
                            instructions: values.instructions,
                            output: values.output,
                            groupId
                        },
                        awaitRefetchQueries: true,
                        refetchQueries: ["templates"]
                    });

                    message = `${data.updateTemplate.message}, da click para usarlo`;

                } else {
                    const { data } = await createTemplate({
                        variables: {
                            groupId,
                            name: values.name,
                            instructions: values.instructions,
                            output: values.output,
                            isPublic: false
                        },
                        awaitRefetchQueries: true,
                        refetchQueries: ["templates"]
                    })

                    message = `${data.createTemplate.message}, da click para usarlo`;
                    templateForm = data.createTemplate.template;

                }
                toast.show(message, {
                    type: "success",
                    duration: 5000,
                    style: {
                        cursor: "pointer",
                        borderRadius: 15,
                    },
                    onPress: () => {
                        handleClickSelect(templateForm)
                    }
                })
            } catch (error) {
                console.log(error);
            }
            formikTemplate.resetForm();
            onDismiss();
        }
    });

    useEffect(() => {
        if (isEdit) {
            formikTemplate.setValues({
                name: template.name,
                instructions: template.instructions,
                output: template.output,
                shareGroup: template.group ? true : false,
                groupId: template.group?.id || ""
            });
            formikTemplate.resetForm({
                values: {
                    name: template.name,
                    instructions: template.instructions,
                    output: template.output,
                    shareGroup: template.group ? true : false,
                    groupId: template.group?.id || ""
                },
                errors: {},
                touched: {}
            });

            formikTemplate.setSubmitting(false);

        } else {
            formikTemplate.resetForm({
                values: {
                    name: '',
                    instructions: '',
                    output: '',
                    shareGroup: false,
                    groupId: ""
                },
                errors: {},
                touched: {}
            });
        }
    }, [template, isEdit]);

    return (
        <Modal
            visible={showForm}
            onDismiss={onDismiss}
            title="Template"
        >
            <View style={{ width: "100%" }} className="flex items-center justify-center">
                <Text className="text-xs m-1 text-white justify-center">
                    Nombre
                </Text>
                <TextInput
                    className='rounded-full'
                    style={{ width: "80%", backgroundColor: '#D9D9D9', borderTopLeftRadius: 20, borderTopRightRadius: 20, borderBottomLeftRadius: 20, borderBottomRightRadius: 20 }}
                    textColor='#000000'
                    placeholderTextColor='#526D82'
                    selectionColor='#000000'
                    cursorColor='#000000'
                    underlineColor='#526D82'
                    activeUnderlineColor='#526D82'
                    mode='flat'
                    placeholder="Desarrollo web html 5"
                    value={formikTemplate.values.name}
                    onChangeText={formikTemplate.handleChange("name")}
                />
                <HelperText type="error" visible={!!formikTemplate.errors.name} style={{ width: "80%" }}>
                    {formikTemplate.errors.name}
                </HelperText>
            </View>

            <View style={{ width: "100%" }} className="flex items-center justify-center mt-2">
                <Text className="text-xs m-1 text-white justify-center" style={{ width: "70%" }}>
                    Instrucciones
                </Text>
                <TextInput
                    className='rounded-full'
                    style={{ width: "80%", backgroundColor: '#D9D9D9', borderTopLeftRadius: 20, borderTopRightRadius: 20, borderBottomLeftRadius: 20, borderBottomRightRadius: 20 }}
                    textColor='#000000'
                    placeholderTextColor='#526D82'
                    selectionColor='#000000'
                    cursorColor='#000000'
                    underlineColor='#526D82'
                    activeUnderlineColor='#526D82'
                    mode='flat'
                    placeholder="Eres una IA experta en html 5 ..."
                    multiline
                    numberOfLines={4}
                    value={formikTemplate.values.instructions}
                    onChangeText={formikTemplate.handleChange("instructions")}
                />
                <HelperText type="error" visible={!!formikTemplate.errors?.instructions} style={{ width: "80%" }}>
                    {formikTemplate.errors.instructions}
                </HelperText>
            </View>

            <View style={{ width: "100%" }} className="flex items-center justify-center mt-2">
                <Text className="text-xs m-1 text-white justify-center" style={{ width: "70%" }}>
                    Output
                </Text>
                <TextInput
                    className=''
                    style={{ width: "80%", backgroundColor: '#D9D9D9', borderTopLeftRadius: 20, borderTopRightRadius: 20, borderBottomLeftRadius: 20, borderBottomRightRadius: 20 }}
                    textColor='#000000'
                    placeholderTextColor='#526D82'
                    selectionColor='#000000'
                    cursorColor='#000000'
                    outlineColor='#000000'
                    underlineColor='#526D82'
                    activeUnderlineColor='#526D82'
                    mode='flat'
                    multiline
                    numberOfLines={3}
                    placeholder="Dame una explicación muy breve y el código relacionado"
                    value={formikTemplate.values.output}
                    onChangeText={formikTemplate.handleChange("output")}
                />
                <HelperText type="error" visible={!!formikTemplate.errors.output} style={{ width: "80%" }}>
                    {formikTemplate.errors.output}
                </HelperText>
            </View>

            <View className="flex flex-row m-5 items-center" style={{ width: "80%" }}>
                <Switch
                    value={formikTemplate.values.shareGroup}
                    onValueChange={() => {
                        formikTemplate.setFieldValue("shareGroup", !formikTemplate.values.shareGroup)
                    }}
                    color='#D9D9D9'

                />
                <Tooltip content={
                    // View con backgroundColor blanco
                    <View className="p-2 bg-white rounded-lg" style={{
                        maxWidth: 300
                    }}>
                        <Text className="text-base" style={{ color: "#595959" }}>{template?.group ? template.group.name : group.name}</Text>
                    </View>

                } placement="bottom-start"
                >
                    <Text className="text-white ml-3" numberOfLines={1} ellipsizeMode="tail">
                        Compartido (con el grupo: <Text className="font-medium">{template?.group ? template.group.name : group.name}</Text>)
                    </Text>
                </Tooltip>
            </View>

            <View className="flex justify-center items-center flex-row overflow-hidden" style={{ width: "80%" }}>
                <Button
                    mode="contained"
                    onPress={() => formikTemplate.handleSubmit()}
                    textColor="#ffffff"
                    buttonColor="#36AE7C"
                    loading={loading}
                    disabled={loading}
                >
                    {isEdit ? "Actualizar" : "Guardar"}
                </Button>
            </View>

        </Modal>
    )
}